import {
  FETCH_PRODUCT_LIST,
  LOAD_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_SEQUENCE,
  CHANGE_PRODUCT_LIST_SEQUENCE,
  UpdateProductListSequenceAction,
  UPDATE_PRODUCT_LIST_SEQUENCE_MODE,
  UpdatePinnedProductsAction,
  UPDATE_PINNED_PRODUCTS,
  UpdatePinnedProductsResponse,
  CHANGE_PINNED_PRODUCTS,
  LOAD_SORTING_LIST,
  FETCH_SORTING_LIST,
  LoadSortingRulesListFromSortAction,
  LOAD_SORTED_PRODUCTS,
  LoadSortedProductsListFromSortAction,
  FETCH_SORTED_PRODUCTS,
  BUSINESS_RULES_SERVICE_URL,
  REFRESH_PRODUCT,
  FETCH_PRODUCT_IDS,
  CategoryToProductData,
  UPDATE_PRODUCT_SEQUENCE,
  UpdateProductSequenceAction,
  UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC,
  UPDATE_PRODUCT_SEQUENCE_LIST_SYNC,
  GetProductSequenceAction,
  GET_PRODUCT_SEQUENCE_LIST,
  GET_PRODUCT_SEQUENCE,
  LOAD_ASYNC_REQUEST_SERVICE_URL,
  LoadProductsFromCategoryAction,
  FETCH_PRODUCT,
  SET_ACTIVE_SORTING_RULE,
  FETCH_PRODUCTS_COLORS,
  REFRESH_PRODUCT_COLOR,
  FETCH_WATCHED_PRODUCT_LIST,
  ADD_PRODUCT_TO_WATCH_LIST,
  REMOVE_PRODUCT_FROM_WATCH_LIST,
  FETCH_PREV_PRODUCT_IDS,
  FETCH_NEXT_PRODUCT_IDS,
  watchProductAction,
  unWatchProductAction,
  CATEGORY_CHANGE,
  LOCK_PRODUCT,
  UNLOCK_PRODUCT,
  FETCH_LOCKS_FOR_CATEGORY,
  LockProductAction,
  LOAD_PRODUCT_POSITION_SERVICE_URL,
  UnLockProductAction,
  GetLocksInCategoryAction,
  LocksInCategory,
  getContentSlotsAction,
  GET_CONTENT_SLOTS,
  LOAD_CONTENT_SLOTS_SERVICE_URL,
  addContentSlotAction,
  ADD_CONTENT_SLOT,
  updateContentSlotAction,
  UPDATE_CONTENT_SLOT,
  THROW_LOCKED_PRODUCT_WARNING,
  THROW_CONTENT_SLOT_WARNING,
  deleteContentSlotAction,
  DELETE_CONTENT_SLOT,
  GET_PINNED_PRODUCTS,
  getPinnedProductsAction,
  SET_PINNED_PRODUCTS,
  pinnedProductsAction,
} from "./ProductListTypes";
import {
  takeEvery,
  put,
  call,
  select,
  all,
  fork,
  join,
  take,
  cancel,
} from "typed-redux-saga/macro";
import GraphQLService from "../../services/GraphQL";
import { callGraphQLApi, callApi, delay } from "../../utils/SagaUtils";
import { updateCategoryPinnedProductsQueryGenerator } from "./ProductListSchema";
import {
  putData,
  getData,
  postData,
  deleteData,
} from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { SEQUENCE_MODE_TYPE } from "../../utils/Constants";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../catalog/CatalogSelectors";
import { selectCurrentCategory } from "../category/CategorySelectors";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import {
  loadProductIdsFromCategory,
  loadPreviousProductIdsFromCategory,
  loadNextProductIdsFromCategory,
  createRefreshProductColorAction,
  refreshProductColorFromList,
  productLoadingCancelled,
  lockProductCompleted,
  unlockProductCompleted,
  updateContentSlotsState,
  deleteContentSlotCompleted,
  getProductSequenceList,
} from "./ProductListActions";
import { getUserEmail } from "../../services/UserService";
import { channel, Task } from "redux-saga";
import {
  selectProductColors,
  selectProducts,
} from "../product/ProductSelectors";
import { selectLockedProducts, selectTotal } from "./ProductListSelectors";
import { avg, max, min, sum } from "../../utils/ArrayUtils";
import {
  LoadProductDetailsResponseAction,
  SET_PRODUCTS_LOADING_STATE,
} from "../product/ProductTypes";
import AppEnv from "../../utils/AppEnv";
import {
  QUERY_PRODUCT_BADGE,
  LoadQueryBadgeAction,
} from "../product-badge/ProductBadgeTypes";
import { fetchProductQueryProduct } from "../product-badge/ProductBadgeOperations";
import { selectConfigValue } from "../app-config/AppConfigSelectors";

let timers: Record<string, number[]> | null = null;

const recordTime = (timerName: string, start: number, end: number) => {
  if (!timers) {
    return;
  }
  if (!timers[timerName]) {
    timers[timerName] = [];
  }
  timers[timerName].push(end - start);
};

const logTimers = () => {
  if (!timers) {
    return;
  }
  console.debug(
    Object.fromEntries(
      Object.entries(timers).map(([key, timings]) => [
        key,
        {
          cnt: timings.length,
          sum: sum(timings),
          avg: avg(timings),
          min: min(timings),
          max: max(timings),
        },
      ]),
    ),
  );
};

function* getProductsFromCategory(action: LoadProductsFromCategoryAction) {
  try {
    yield* put({
      type: FETCH_PRODUCT_LIST.REQUEST,
      payload: action.payload,
    });

    const actionType = FETCH_PRODUCT_LIST;
    const categoryId = action.payload.categoryId;
    const catalogId = action.payload.catalogId;
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeCode;
    const constName = Object.keys({ FETCH_PRODUCT_LIST })[0].toString();
    const productIds = action.payload.productIds;
    const totalPagesFetched = action.payload.totalPagesFetched;
    const storeListIds = action.payload.storeListIds;
    const isRefreshingAllProducts = action.payload.isRefreshingAllProducts;
    let commaSeperatedProductIds: string = "";
    if (productIds && Array.isArray(productIds) && productIds.length) {
      commaSeperatedProductIds = String(
        productIds.length > 1
          ? productIds.join(",").toString()
          : productIds.length && productIds[0],
      );
    }

    const endpoint = acquireEndpoint(
      constName,
      commaSeperatedProductIds,
      isRefreshingAllProducts ? "true" : "false",
    );
    //const endpoint = acquireEndpoint(constName, categoryId);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const t0 = Date.now();
    const response = yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    const t1 = Date.now();
    recordTime(constName, t0, t1);
    if (response && response.payload) {
      //pagesFetched:action.payload.numberOfAPIcallsInchunks
      yield* put<LoadProductDetailsResponseAction>({
        type: FETCH_PRODUCT_LIST.SUCCESS,
        payload: {
          ...response.payload,
          categoryId,
          totalPagesFetched,
          storeListIds,
        },
      });

      let productIds: string[] = [];
      for (const result of response.payload.results) {
        const isProductHasColors =
          result.cachedProduct?.product?.hasColors ?? false;
        if (isProductHasColors) {
          productIds.push(result.cachedProduct.product.productId);
        }
      }
      if (productIds.length > 0) {
        const colorProductIds = productIds.join(",");
        yield* all([getProductsColorData(action, colorProductIds)]);
      } else {
        yield* put({ type: SET_PRODUCTS_LOADING_STATE });
      }
    } else {
      //pagesFetched:action.payload.numberOfAPIcallsInchunks
      yield* put({
        type: FETCH_PRODUCT_LIST.FAILURE,
        message: "Something went wrong!!",
        payload: { totalPagesFetched },
      });
    }
  } catch (e: any) {
    //pagesFetched:action.payload.numberOfAPIcallsInchunks
    yield* put({ type: FETCH_PRODUCT_LIST.FAILURE, message: e.message });
  }
}

function* getProductsColorData(
  action: LoadProductsFromCategoryAction,
  productIds: string,
) {
  try {
    const actionType = FETCH_PRODUCTS_COLORS;
    const constName = Object.keys({ FETCH_PRODUCTS_COLORS })[0].toString();
    const endpoint = acquireEndpoint(
      constName,
      productIds,
      action.payload?.isRefreshingAllProducts ? "true" : "false",
    );
    const headersObj = {
      "x-store-id": action.payload?.storeId,
      "x-locale-code": action.payload?.localeCode || "default",
    };
    const t0 = Date.now();
    yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    const t1 = Date.now();
    recordTime(constName, t0, t1);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* updateProductSequence(action: UpdateProductListSequenceAction) {
  let actionType = UPDATE_PRODUCT_LIST_SEQUENCE;
  const categoryId = action.payload.categoryId;
  const productSequences = action.payload.productSequences;
  const storeId = yield* select(selectCurrentStoreId);
  const catalogId = action.payload.catalogId;
  const constName = Object.keys({ UPDATE_PRODUCT_LIST_SEQUENCE })[0].toString();
  const localeCode = yield* select(selectCurrentLocale);

  const headersObj = {
    "x-store-id": storeId,
    "x-catalog-id": catalogId,
    "x-locale-code": localeCode || "default",
    "x-currency-code": "USD",
  };

  try {
    for (const item of productSequences) {
      const endpoint = acquireEndpoint(constName, categoryId, item.productId);
      const payload = {
        productId: item.productId,
        sequence: item.sequence,
        categoryId: categoryId,
      };

      const result = yield callApi(
        actionType,
        putData,
        payload,
        endpoint,
        headersObj,
      );
      /**
       * TODO : Add success and error handling
       */
      if (result) {
        if (result.type === actionType.SUCCESS) {
          if (
            result.payload.data &&
            result.payload.data.insert_SyncLog.affected_rows
          ) {
          }
        }
      }
    }
    yield* put({
      type: UPDATE_PRODUCT_LIST_SEQUENCE_MODE,
      sequenceMode: SEQUENCE_MODE_TYPE.VIEW,
    });
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateProductSequenceAsynchronously(
  action: UpdateProductSequenceAction,
) {
  yield* put({ type: UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC.REQUEST });
  let actionType = UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC;
  try {
    const categoryId = action.payload.categoryId;
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const storeId = yield* select(selectCurrentStoreId);
    const constName = Object.keys({
      UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC,
    })[0].toString();
    const headersObj = {
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
    };
    const payload = {
      categoryId,
      productIds: action.payload.productIds,
    };
    const endpoint = acquireEndpoint(constName, categoryId);
    const optionsObj = {
      showGenericError: false,
    };
    const response = yield* call(
      callApi,
      actionType,
      putData,
      payload,
      endpoint,
      headersObj,
      optionsObj,
    );
    if (response && response.type === actionType.FAILURE) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productListOperations.updateProductSequenceFailure",
                defaultMessage:
                  "Failed to update product sequences, please try after sometime.",
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    } else {
      yield* call(
        getProductSequenceFn,
        getProductSequenceList({
          requestId: response.payload.requestId,
          isUpdateFunctionInvoked: true,
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE });
    yield* put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "productListOperations.updateProductSequenceFailure",
              defaultMessage:
                "Failed to update product sequences, please try again after some time.",
            },
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
  }
}

function* updateProductSequenceSynchronously(
  action: UpdateProductSequenceAction,
) {
  yield* put({ type: UPDATE_PRODUCT_SEQUENCE_LIST_SYNC.REQUEST });
  let actionType = UPDATE_PRODUCT_SEQUENCE_LIST_SYNC;
  try {
    const categoryId = action.payload.categoryId;
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const storeId = yield* select(selectCurrentStoreId);
    const constName = Object.keys({
      UPDATE_PRODUCT_SEQUENCE_LIST_SYNC,
    })[0].toString();
    const headersObj = {
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode ?? "default",
      "x-currency-code": "USD",
    };
    const payload = {
      categoryId,
      productIds: action.payload.productIds,
    };
    const endpoint = acquireEndpoint(constName, categoryId);
    const optionsObj = {
      showGenericError: false,
    };
    const response = yield* call(
      callApi,
      actionType,
      putData,
      payload,
      endpoint,
      headersObj,
      optionsObj,
    );
    if (response && response.type === actionType.FAILURE) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productListOperations.updateProductSequenceFailure",
                defaultMessage:
                  "Failed to update product sequences, please try after sometime.",
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    } else {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productListOperations.updateProductSequenceSuccess",
                defaultMessage: "Updated product sequences.",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE });
    yield* put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "productListOperations.updateProductSequenceFailure",
              defaultMessage:
                "Failed to update product sequences, please try again after some time.",
            },
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
  }
}

function* updateProductSequenceFn(action: UpdateProductSequenceAction) {
  const isAsyncMode = AppEnv.SAVE_MODE === "async";
  if (isAsyncMode) {
    yield* call(updateProductSequenceAsynchronously, action);
  } else {
    yield* call(updateProductSequenceSynchronously, action);
  }
}

function* getProductSequenceFn(action: GetProductSequenceAction) {
  const actionType = GET_PRODUCT_SEQUENCE_LIST;
  try {
    const requestId = action.payload.requestId;
    const isUpdateFunctionInvoked = action.payload.isUpdateFunctionInvoked;
    const constName = Object.keys({ GET_PRODUCT_SEQUENCE_LIST })[0].toString();
    const serviceType = LOAD_ASYNC_REQUEST_SERVICE_URL;
    const endpoint = acquireEndpoint(constName, serviceType, requestId);
    const optionsObj = {
      showGenericError: false,
    };
    while (true) {
      const res = yield* call(
        callApi,
        actionType,
        getData,
        null,
        endpoint,
        {},
        optionsObj,
      );
      const result = res.payload;
      if (result && result?.response?.statusCode) {
        if (result?.response?.statusCode <= 204) {
          yield* put({ type: GET_PRODUCT_SEQUENCE_LIST.DONE });
          if (isUpdateFunctionInvoked === true) {
            yield* put(
              addGlobalAlertState({
                alertsProps: [
                  {
                    descriptor: {
                      id: "productListOperations.updateProductSequenceSuccess",
                      defaultMessage: "Updated product sequences.",
                    },
                    severity: "success",
                    variant: "standard",
                  },
                ],
              }),
            );
          }
          break;
        } else if (result?.response?.statusCode >= 400) {
          yield* put({
            type: GET_PRODUCT_SEQUENCE_LIST.FAILURE,
          });
          yield* put(
            addGlobalAlertState({
              alertsProps: [
                {
                  descriptor: {
                    id: "productListOperations.updateProductSequenceFailure",
                    defaultMessage:
                      "Failed to update product sequences, please try again after some time.",
                  },
                  severity: "error",
                  variant: "standard",
                },
              ],
            }),
          );
          break;
        }
      }
      yield* call(delay, 5000);
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: GET_PRODUCT_SEQUENCE_LIST.FAILURE });
    yield* put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "clipBoardArea.allCodesInvalid",
              defaultMessage:
                "Failed to update product sequences, please try again after some time.",
            },
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
  }
}

function* updatePinnedProducts(action: UpdatePinnedProductsAction) {
  let actionType = UPDATE_PINNED_PRODUCTS;

  try {
    const storeId = action.payload.storeId;
    const catalogId = action.payload.catalogId;
    const categoryId = action.payload.categoryId;
    const productIds = action.payload.pinnedProducts;

    const syncLogInsertForDeleted = {
      tableName: "PinnedProducts",
      operationType: "DELETED",
      keyName1: "storeId",
      oldKeyValue1: storeId,
      newKeyValue1: storeId,
      keyName2: "catalogId",
      oldKeyValue2: catalogId,
      newKeyValue2: catalogId,
      keyName3: "categoryId",
      oldKeyValue3: categoryId,
      newKeyValue3: categoryId,
    };

    const syncLogInserts = [
      syncLogInsertForDeleted,
      ...productIds.map((productId) => {
        return {
          tableName: "PinnedProducts",
          operationType: "INSERTED",
          keyName1: "storeId",
          oldKeyValue1: storeId,
          newKeyValue1: storeId,
          keyName2: "catalogId",
          oldKeyValue2: catalogId,
          newKeyValue2: catalogId,
          keyName3: "categoryId",
          oldKeyValue3: categoryId,
          newKeyValue3: categoryId,
          keyName4: "productId",
          oldKeyValue4: productId,
          newKeyValue4: productId,
        };
      }),
    ];

    const query = {
      query: updateCategoryPinnedProductsQueryGenerator(productIds),
      variables: {
        storeId,
        catalogId,
        categoryId,
        syncLogInserts,
      },
    };
    const result: UpdatePinnedProductsResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );
    /**
     * TODO : Add success and error handling
     */
    if (result) {
      if (result.type === actionType.SUCCESS) {
        if (
          result.payload.data &&
          result.payload.data.insert_SyncLog.affected_rows
        ) {
        }
      }
      yield* put({
        type: UPDATE_PRODUCT_LIST_SEQUENCE_MODE,
        sequenceMode: SEQUENCE_MODE_TYPE.VIEW,
      });
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  }
}

function* getSortingRulesList(action: LoadSortingRulesListFromSortAction) {
  try {
    const actionType = FETCH_SORTING_LIST;
    const constName = Object.keys({ FETCH_SORTING_LIST })[0].toString();
    const serviceType = BUSINESS_RULES_SERVICE_URL;
    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error) {
    console.log("error", error);
  }
}

function* getSortedProductsList(action: LoadSortedProductsListFromSortAction) {
  try {
    const actionType = FETCH_SORTED_PRODUCTS;

    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const constName = Object.keys({ FETCH_SORTED_PRODUCTS })[0].toString();
    const localeCode = yield* select(selectCurrentLocale);
    const categoryId = yield* select(selectCurrentCategory);
    const ruleId = action.rule;
    const sortPeriod = action.selectedSortPeriod;
    const timeZone = action.timeZone;
    const serviceType = BUSINESS_RULES_SERVICE_URL;
    const lockedProducts = yield* select(
      selectLockedProducts,
      categoryId,
      catalogId,
    );

    const payload = {
      storeId: storeId,
      catalogId: catalogId,
      localeCode: localeCode || "default",
      currencyCode: "USD",
      categoryId: categoryId,
      dateRange: sortPeriod,
      timezone: timeZone,
      lockedProductIds:
        lockedProducts?.map((lock) => {
          return {
            productId: lock.productId,
            position: lock.position,
          };
        }) || [],
    };
    const endpoint = acquireEndpoint(constName, serviceType, ruleId);
    const response = yield* call(
      callApi,
      actionType,
      postData,
      payload,
      endpoint,
      {},
    );
    if (response?.type === actionType.SUCCESS) {
      yield* put({
        type: SET_ACTIVE_SORTING_RULE,
        payload: ruleId,
      });
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

function* getUpdatedProductInfo(action) {
  try {
    const actionType =
      action.type === REFRESH_PRODUCT.REQUEST ? REFRESH_PRODUCT : FETCH_PRODUCT;
    const catalogId = action.payload.catalogId;
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeCode;
    const productId = action.payload.productId;

    const constName = action.type.toString();

    const endpoint = acquireEndpoint(constName, productId);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const response = yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    if (
      response &&
      response.payload &&
      action.type === REFRESH_PRODUCT.REQUEST
    ) {
      yield* put({
        type: REFRESH_PRODUCT.SUCCESS,
        payload: response.payload as CategoryToProductData,
      });

      const isProductHasColors =
        response.payload.cachedProduct?.product?.hasColors ?? false;
      if (isProductHasColors) {
        yield* all([
          getUpdatedProductColorInfo(
            refreshProductColorFromList({ productId, localeCode, storeId }),
          ),
        ]);
      }
    } else if (
      response &&
      response.payload &&
      action.type === FETCH_PRODUCT.REQUEST
    ) {
      if (
        action.payload.isProductAddedFromClipBoard ||
        action.payload.isProductAddedFromVariationMgmt
      ) {
        const isProductHasColors =
          response.payload.cachedProduct?.product?.hasColors ?? false;
        if (isProductHasColors) {
          yield* all([
            getUpdatedProductColorInfo(
              refreshProductColorFromList({ productId, localeCode, storeId }),
            ),
          ]);
        }
      }
      //yield put({ type: FETCH_PRODUCT.SUCCESS, payload: response.payload as CategoryToProductData })
    } else if (action.type === REFRESH_PRODUCT.REQUEST) {
      yield* put({ type: REFRESH_PRODUCT.FAILURE, payload: action.payload });
    } else if (action.type === FETCH_PRODUCT.REQUEST) {
      yield* put({ type: FETCH_PRODUCT.FAILURE, payload: action.payload });
    }
  } catch (e: any) {
    console.error(e, e.message);
    yield* put({ type: REFRESH_PRODUCT.FAILURE, payload: action.payload });
  }
}

const LOAD_CATEGORY_PRODUCTS_TIMER = "LOAD CATEGORY PRODUCTS";
function* getProductIdsFromCategory(
  action: ReturnType<typeof loadProductIdsFromCategory>,
) {
  try {
    // performance tuning knobs
    /**
     * The max number of productIds to fetch in a single request.
     */
    const PRODUCT_IDS_PAGESIZE = +(AppEnv.PRODUCT_IDS_PAGESIZE ?? 100);

    /**
     * The number of workers to fetch product details concurrently.
     */
    const PRODUCT_DETAILS_MAX_WORKERS = +(
      AppEnv.PRODUCT_DETAILS_MAX_WORKERS ?? 6
    );

    /**
     * The max number of product details to fetch in a single request
     */
    const PRODUCT_DETAILS_PAGESIZE = +(AppEnv.PRODUCT_DETAILS_PAGESIZE ?? 4);
    // New chunk size specifically for badges
    const PRODUCT_BADGES_PAGESIZE = +(AppEnv.PRODUCT_BADGES_PAGESIZE ?? 4);

    console.debug({
      PRODUCT_IDS_PAGESIZE,
      PRODUCT_DETAILS_PAGESIZE,
      PRODUCT_DETAILS_MAX_WORKERS,
    });

    console.time(LOAD_CATEGORY_PRODUCTS_TIMER);

    yield* put({ type: FETCH_PRODUCT_LIST.START });

    const actionType = FETCH_PRODUCT_IDS;
    const {
      categoryId,
      catalogId,
      storeId,
      localeCode,
      isRefreshingAllProducts,
      storeListIds,
    } = action.payload;
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    let next = action.payload?.next || "";
    let allProductIds: string[] = action.payload.productIds || []; // Start with initial productIds

    timers = {};

    const badgeOverlayEnabled = yield* select(
      selectConfigValue,
      "badgeOverlayEnabled",
    );

    const workChannel = yield* call(
      channel<LoadProductsFromCategoryAction | "END">,
    );
    const productBadgeChannel = yield* call(
      channel<LoadQueryBadgeAction | "END">,
    );

    // Create separate worker for badge processing
    const badgeWorker = yield* fork(function* () {
      while (true) {
        const action = yield* take(productBadgeChannel);
        if (action === "END") {
          break;
        }
        yield* call(fetchProductQueryProduct, action);
      }
    });

    // Create workers for product details (now without badge coupling)
    const tasks: Task[] = [];
    for (let i = 0; i < PRODUCT_DETAILS_MAX_WORKERS; i++) {
      const task = yield* fork(function* worker() {
        while (true) {
          const action = yield* take(workChannel);
          if (action === "END") {
            break;
          }
          yield* call(getProductsFromCategory, action);
        }
      });
      tasks.push(task);
    }

    while (true) {
      const constName = FETCH_PRODUCT_IDS.REQUEST.toString();
      const opts: Record<string, string | number> = {
        count: PRODUCT_IDS_PAGESIZE,
      };
      if (next) {
        opts.next = next;
      }
      const endpoint = acquireEndpoint(constName, categoryId, opts);

      // Call product ids API
      const t0 = Date.now();
      const result = yield* call(
        callApi,
        actionType,
        getData,
        null,
        endpoint,
        headersObj,
      );
      const t1 = Date.now();

      if (result !== null) {
        recordTime(constName, t0, t1);
      }

      if (result?.payload) {
        const productIds = result.payload.productIds;

        // Accumulate product IDs
        allProductIds = [...allProductIds, ...productIds];

        // Check if there are more product IDs to load
        if (result.payload.next) {
          next = result.payload.next;
        } else {
          break; // Exit the loop if there's no more data
        }
      } else {
        yield* put({ type: FETCH_PRODUCT_IDS.FAILURE });
        break; // Exit the loop if there's no data
      }
    }

    // Now that all product IDs are loaded, send work to the workers
    let totalPagesFetched = 0;
    let numberOfAPIcallsInChunks = Math.ceil(
      allProductIds.length / PRODUCT_DETAILS_PAGESIZE,
    );
    let currentIndex = 0;

    while (numberOfAPIcallsInChunks !== 0) {
      numberOfAPIcallsInChunks--;
      totalPagesFetched += 1;
      const productIdsChunk = allProductIds.slice(
        currentIndex,
        currentIndex + PRODUCT_DETAILS_PAGESIZE,
      );

      // Send work to the workers
      yield* put(workChannel, {
        type: LOAD_PRODUCT_LIST,
        payload: {
          productIds: productIdsChunk,
          catalogId,
          storeId,
          localeCode,
          categoryId,
          totalPagesFetched,
          storeListIds,
          isRefreshingAllProducts,
        },
      });

      currentIndex += PRODUCT_DETAILS_PAGESIZE;
    }

    if (badgeOverlayEnabled === "true") {
      currentIndex = 0;
      while (currentIndex < allProductIds.length) {
        const badgeProductIdsChunk = allProductIds.slice(
          currentIndex,
          currentIndex + PRODUCT_BADGES_PAGESIZE,
        );

        yield* put(productBadgeChannel, {
          type: QUERY_PRODUCT_BADGE.REQUEST,
          payload: {
            productIds: badgeProductIdsChunk,
          },
        });

        currentIndex += PRODUCT_BADGES_PAGESIZE;
      }
    }

    // Stop workers
    for (let i = 0; i < PRODUCT_DETAILS_MAX_WORKERS; i++) {
      yield* put(workChannel, "END");
    }
    yield* put(productBadgeChannel, "END");

    // Wait for all workers to finish
    yield* join([...tasks, badgeWorker]);

    // log performance metrics
    console.timeEnd(LOAD_CATEGORY_PRODUCTS_TIMER);
    logTimers();

    // log fetch counts
    const products: ReturnType<typeof selectProducts> =
      yield* select(selectProducts);
    const productsLoaded = Object.keys(products).length;
    const total: ReturnType<typeof selectTotal> = yield* select(selectTotal);
    const productsWithColor = Object.values(products).filter(
      (p) => p.hasColors,
    ).length;
    const productColors: ReturnType<typeof selectProductColors> =
      yield* select(selectProductColors);
    const productColorsLoaded = Object.keys(productColors).length;
    console.debug({
      productsLoaded,
      total,
      productsWithColor,
      productColorsLoaded,
    });

    timers = null;
  } catch (e: any) {
    yield* put({ type: FETCH_PRODUCT_IDS.FAILURE, message: e.message });
  } finally {
    yield* put({ type: FETCH_PRODUCT_LIST.STOP });
  }
}

function* getPreviousProductIdsFromCategory(
  action: ReturnType<typeof loadPreviousProductIdsFromCategory>,
) {
  try {
    const actionType = FETCH_PREV_PRODUCT_IDS;
    const categoryId = action.payload.categoryId;
    const catalogId = action.payload.catalogId;
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeCode;
    const isRefreshingAllProducts = action.payload.isRefreshingAllProducts;
    const constName = FETCH_PRODUCT_IDS.REQUEST.toString();
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const storeListIds = action.payload.storeListIds;
    let next = Number(action.payload?.next) ?? 0;
    let nextCheck = 0;
    if (action.payload?.next && action.payload?.count) {
      nextCheck = Number(action.payload?.next) % action.payload?.count;
      if (nextCheck > 0) {
        next -= nextCheck;
      }
    }
    const endpoint = acquireEndpoint(constName, categoryId, String(next));
    const result = !action.payload.productIds
      ? yield* call(callApi, actionType, getData, null, endpoint, headersObj)
      : null;
    if ((result && result.payload) || action.payload.productIds) {
      const productIds =
        result?.payload?.productIds || action.payload.productIds;
      const productCount = productIds.length;
      let totalPagesFetched = 0;
      let numberOfAPIcallsInchunks = Math.ceil(productCount / 4); // considering calling 4 items at a time;
      let currentIndex = 0;

      while (numberOfAPIcallsInchunks !== 0) {
        numberOfAPIcallsInchunks--;
        totalPagesFetched += 1;
        const productIdsChunk = productIds.slice(
          currentIndex,
          currentIndex + 4,
        );
        yield* put({
          type: LOAD_PRODUCT_LIST,
          payload: {
            productIds: productIdsChunk,
            catalogId,
            storeId,
            localeCode,
            categoryId,
            totalPagesFetched,
            storeListIds,
            isRefreshingAllProducts,
          },
        });
        currentIndex = currentIndex + 4;
      }
    } else {
      yield* put({ type: FETCH_PRODUCT_IDS.FAILURE });
    }
  } catch (e: any) {
    yield* put({ type: FETCH_PRODUCT_IDS.FAILURE, message: e.message });
  }
}

function* getNextProductIdsFromCategory(
  action: ReturnType<typeof loadNextProductIdsFromCategory>,
) {
  try {
    const actionType = FETCH_PREV_PRODUCT_IDS;
    const categoryId = action.payload.categoryId;
    const catalogId = action.payload.catalogId;
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeCode;
    const isRefreshingAllProducts = action.payload.isRefreshingAllProducts;
    const constName = FETCH_PRODUCT_IDS.REQUEST.toString();
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const storeListIds = action.payload.storeListIds;
    const next = action.payload?.next || "";
    const endpoint = acquireEndpoint(constName, categoryId, next);

    const result = !action.payload.productIds
      ? yield* call(callApi, actionType, getData, null, endpoint, headersObj)
      : null;
    if ((result && result.payload) || action.payload.productIds) {
      const productIds =
        result?.payload?.productIds || action.payload.productIds;
      const productCount = productIds.length;
      let totalPagesFetched = 0;
      let numberOfAPIcallsInchunks = Math.ceil(productCount / 4); // considering calling 4 items at a time;
      let currentIndex = 0;

      while (numberOfAPIcallsInchunks !== 0) {
        numberOfAPIcallsInchunks--;
        totalPagesFetched += 1;
        const productIdsChunk = productIds.slice(
          currentIndex,
          currentIndex + 4,
        );
        yield* put({
          type: LOAD_PRODUCT_LIST,
          payload: {
            productIds: productIdsChunk,
            catalogId,
            storeId,
            localeCode,
            categoryId,
            totalPagesFetched,
            storeListIds,
            isRefreshingAllProducts,
          },
        });
        currentIndex = currentIndex + 4;
      }
    } else {
      yield* put({ type: FETCH_PRODUCT_IDS.FAILURE });
    }
  } catch (e: any) {
    yield* put({ type: FETCH_PRODUCT_IDS.FAILURE, message: e.message });
  }
}

function* getUpdatedProductColorInfo(action) {
  try {
    const actionType = REFRESH_PRODUCT_COLOR;
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeCode;
    const productId = action.payload.productId;

    const constName = action.type.toString();

    const endpoint = acquireEndpoint(constName, productId);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-store-id": storeId,
    };
    const response = yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    if (
      response &&
      response.payload &&
      action.type === REFRESH_PRODUCT_COLOR.SUCCESS
    ) {
      yield* put(createRefreshProductColorAction(response.payload));
    }
  } catch (e: any) {
    console.error(e, e.message);
    yield* put({
      type: REFRESH_PRODUCT_COLOR.FAILURE,
      payload: action.payload,
    });
  }
}

function* getWatchedProductList() {
  try {
    const actionType = FETCH_WATCHED_PRODUCT_LIST;
    const constName = Object.keys({ FETCH_WATCHED_PRODUCT_LIST })[0].toString();
    const email = yield* select(getUserEmail);
    const endpoint = acquireEndpoint(constName, email);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* addProductToWatchList(action: watchProductAction) {
  try {
    const actionType = ADD_PRODUCT_TO_WATCH_LIST;
    const constName = Object.keys({ ADD_PRODUCT_TO_WATCH_LIST })[0].toString();
    const endpoint = acquireEndpoint(constName);
    yield* call(callApi, actionType, postData, action.payload, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* removeProductFromWatchList(action: unWatchProductAction) {
  try {
    const actionType = REMOVE_PRODUCT_FROM_WATCH_LIST;
    const constName = Object.keys({
      REMOVE_PRODUCT_FROM_WATCH_LIST,
    })[0].toString();

    const endpoint = acquireEndpoint(constName);
    yield* call(callApi, actionType, postData, action.payload, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* lockProduct(action: LockProductAction) {
  try {
    const actionType = LOCK_PRODUCT;
    const constName = Object.keys({ LOCK_PRODUCT })[0].toString();
    const { lockedProducts } = action.payload;
    const categoryId = yield* select(selectCurrentCategory);
    const catalogId = yield* select(selectCurrentCatalogId);
    const serviceType = LOAD_PRODUCT_POSITION_SERVICE_URL;
    // Create an array of lock operations to run in parallel
    const lockOperations = lockedProducts?.map(({ productId, position }) => {
      const endpoint = acquireEndpoint(
        constName,
        serviceType,
        catalogId,
        categoryId,
        position,
      );

      return call(callApi, actionType, putData, { productId }, endpoint, {});
    });

    // Execute all lock operations in parallel
    const responses = yield* all(lockOperations);

    // Process successful locks and dispatch completion actions
    const successfulLocks = responses
      ?.map((response, index) => {
        if (response?.type === actionType.SUCCESS) {
          const { productId, position } = lockedProducts[index];
          return {
            catalogId,
            categoryId,
            position,
            productId,
          };
        }
        return null;
      })
      ?.filter(Boolean) as LocksInCategory[];

    if (successfulLocks.length > 0) {
      for (const lock of successfulLocks) {
        yield* put(lockProductCompleted(lock));
      }
    }
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: LOCK_PRODUCT.FAILURE, payload: action.payload });
  }
}

function* unLockProduct(action: UnLockProductAction) {
  try {
    const actionType = UNLOCK_PRODUCT;
    const constName = Object.keys({ UNLOCK_PRODUCT })[0].toString();
    const { unlockedProducts } = action.payload;
    const categoryId = yield* select(selectCurrentCategory);
    const catalogId = yield* select(selectCurrentCatalogId);
    const serviceType = LOAD_PRODUCT_POSITION_SERVICE_URL;
    const unlockOperations = unlockedProducts?.map(({ position }) => {
      const endpoint = acquireEndpoint(
        constName,
        serviceType,
        catalogId,
        categoryId,
        position,
      );
      return call(callApi, actionType, deleteData, null, endpoint, {});
    });
    const responses = yield* all(unlockOperations);
    const successfulUnlocks = responses
      ?.map((response, index) => {
        if (response?.type === actionType.SUCCESS) {
          const { position, productId } = unlockedProducts[index];
          return {
            catalogId,
            categoryId,
            position,
            productId,
          };
        }
        return null;
      })
      ?.filter(Boolean) as LocksInCategory[];
    if (successfulUnlocks.length > 0) {
      for (const unlock of successfulUnlocks) {
        yield* put(unlockProductCompleted(unlock));
      }
    }
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: UNLOCK_PRODUCT.FAILURE, payload: action.payload });
  }
}

function* getLocksForCategory(action: GetLocksInCategoryAction) {
  try {
    const actionType = FETCH_LOCKS_FOR_CATEGORY;
    const constName = Object.keys({ FETCH_LOCKS_FOR_CATEGORY })[0].toString();
    const { catalogId, categoryId } = action.payload;
    const serviceType = LOAD_PRODUCT_POSITION_SERVICE_URL;
    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      catalogId,
      categoryId,
    );
    yield* call(callApi, actionType, getData, null, endpoint, {});
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: FETCH_LOCKS_FOR_CATEGORY.FAILURE });
  }
}

function* getContentSlots(action: getContentSlotsAction) {
  try {
    const actionType = GET_CONTENT_SLOTS;
    const constName = Object.keys({ GET_CONTENT_SLOTS })[0].toString();
    const { catalogId, categoryId, storeId } = action.payload;
    const serviceType = LOAD_CONTENT_SLOTS_SERVICE_URL;
    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      storeId,
      catalogId,
      categoryId,
    );
    yield* call(callApi, actionType, getData, null, endpoint, {});
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: GET_CONTENT_SLOTS.FAILURE });
  }
}

function* addContentSlot(action: addContentSlotAction) {
  try {
    const actionType = ADD_CONTENT_SLOT;
    const constName = Object.keys({ ADD_CONTENT_SLOT })[0].toString();
    const serviceType = LOAD_CONTENT_SLOTS_SERVICE_URL;
    const endpoint = acquireEndpoint(constName, serviceType);
    const response = yield* call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
    );
    if (response?.type === actionType.SUCCESS) {
      const payload = {
        slotId: response.payload.slotId,
        position: action.payload.position,
      };
      yield* put(updateContentSlotsState(payload));
    }
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: ADD_CONTENT_SLOT.FAILURE, payload: action.payload });
  }
}

function* updateContentSlot(action: updateContentSlotAction) {
  try {
    const actionType = UPDATE_CONTENT_SLOT;
    const constName = Object.keys({ UPDATE_CONTENT_SLOT })[0].toString();
    const serviceType = LOAD_CONTENT_SLOTS_SERVICE_URL;
    const { slotId, position } = action.payload;
    const endpoint = acquireEndpoint(constName, serviceType, slotId);
    const response = yield* call(
      callApi,
      actionType,
      putData,
      { position },
      endpoint,
    );
    if (response?.type === actionType.SUCCESS) {
      yield* put(updateContentSlotsState({ slotId, position }));
    }
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: UPDATE_CONTENT_SLOT.FAILURE, payload: action.payload });
  }
}

function* throwLockedProductWarning() {
  yield* put(
    addGlobalAlertState({
      alertsProps: [
        {
          descriptor: {
            id: "productListOperations.lockedProductWarning",
            defaultMessage:
              "Another product is locked in place in the adjacent slot. Please unlock the product or select a different product.",
          },
          severity: "warning",
          variant: "standard",
        },
      ],
    }),
  );
}

function* throwContentSlotWarning() {
  yield* put(
    addGlobalAlertState({
      alertsProps: [
        {
          descriptor: {
            id: "productListOperations.contentSlotWarning",
            defaultMessage:
              "A content slot already exists in the adjacent slot. Please select a different product.",
          },
          severity: "warning",
          variant: "standard",
        },
      ],
    }),
  );
}

function* deleteContentSlot(action: deleteContentSlotAction) {
  try {
    const actionType = DELETE_CONTENT_SLOT;
    const constName = Object.keys({ DELETE_CONTENT_SLOT })[0].toString();
    const { slotId } = action.payload;
    const serviceType = LOAD_CONTENT_SLOTS_SERVICE_URL;
    const endpoint = acquireEndpoint(constName, serviceType, slotId);
    const response = yield* call(
      callApi,
      actionType,
      deleteData,
      null,
      endpoint,
      {},
    );
    if (response?.type === actionType.SUCCESS) {
      yield* put(deleteContentSlotCompleted({ slotId }));
    }
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: DELETE_CONTENT_SLOT.FAILURE, payload: action.payload });
  }
}

function* getPinnedProducts(action: getPinnedProductsAction) {
  try {
    const actionType = GET_PINNED_PRODUCTS;
    const constName = Object.keys({ GET_PINNED_PRODUCTS })[0].toString();
    const serviceType = LOAD_PRODUCT_POSITION_SERVICE_URL;
    const { catalogId, categoryId } = action.payload;
    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      catalogId,
      categoryId,
    );
    yield* call(callApi, actionType, getData, null, endpoint, {});
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: GET_PINNED_PRODUCTS.FAILURE });
  }
}

function* setPinnedProducts(action: pinnedProductsAction) {
  try {
    const actionType = SET_PINNED_PRODUCTS;
    const constName = Object.keys({ SET_PINNED_PRODUCTS })[0].toString();
    const { catalogId, categoryId, pinnedProductIds, sortOption } =
      action.payload;
    const serviceType = LOAD_PRODUCT_POSITION_SERVICE_URL;
    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      catalogId,
      categoryId,
    );
    yield* call(
      callApi,
      actionType,
      putData,
      { pinnedProductIds, sortOption },
      endpoint,
    );
  } catch (error: any) {
    console.log("error", error);
    yield* put({ type: SET_PINNED_PRODUCTS.FAILURE, payload: action.payload });
  }
}

export function* watchLoadProductsFromCategory() {
  yield* takeEvery(LOAD_PRODUCT_LIST, getProductsFromCategory);
}

export function* watchCategoryChange() {
  let task;
  while (true) {
    const action = yield* take(CATEGORY_CHANGE);
    if (task) {
      yield* cancel(task); // Cancel previous loading task if it's inflight
      yield* put(productLoadingCancelled());
    }
    task = yield* fork(
      getProductsFromCategory,
      action as LoadProductsFromCategoryAction,
    ); // Start a new product loading task
  }
}

export function* watchUpdateProductListSequence() {
  yield* takeEvery(CHANGE_PRODUCT_LIST_SEQUENCE, updateProductSequence);
}
export function* watchUpdateProductSequence() {
  yield* takeEvery(UPDATE_PRODUCT_SEQUENCE, updateProductSequenceFn);
}
export function* watchUpdatePinnedProducts() {
  yield* takeEvery(CHANGE_PINNED_PRODUCTS, updatePinnedProducts);
}

export function* watchLoadSortingRulesList() {
  yield* takeEvery(LOAD_SORTING_LIST, getSortingRulesList);
}

export function* watchSortedProductsList() {
  yield* takeEvery(LOAD_SORTED_PRODUCTS, getSortedProductsList);
}

export function* watchRefreshProductAction() {
  yield* takeEvery(REFRESH_PRODUCT.REQUEST, getUpdatedProductInfo);
}

export function* watchProductIdsFromCategory() {
  yield* takeEvery(FETCH_PRODUCT_IDS.REQUEST, getProductIdsFromCategory);
}

export function* watchPrevProductIdsFromCategory() {
  yield* takeEvery(
    FETCH_PREV_PRODUCT_IDS.REQUEST,
    getPreviousProductIdsFromCategory,
  );
}

export function* watchNextProductIdsFromCategory() {
  yield* takeEvery(
    FETCH_NEXT_PRODUCT_IDS.REQUEST,
    getNextProductIdsFromCategory,
  );
}

export function* watchGetProductSequenceList() {
  yield* takeEvery(GET_PRODUCT_SEQUENCE, getProductSequenceFn);
}

export function* watchFetchUpdatedProductAction() {
  yield* takeEvery(FETCH_PRODUCT.REQUEST, getUpdatedProductInfo);
}

export function* watchRefreshProductColorAction() {
  yield* takeEvery(REFRESH_PRODUCT_COLOR.REQUEST, getUpdatedProductColorInfo);
}

export function* watchLoadProductListAction() {
  yield* takeEvery(FETCH_WATCHED_PRODUCT_LIST.REQUEST, getWatchedProductList);
}

export function* watchAddProductToWatchList() {
  yield* takeEvery(ADD_PRODUCT_TO_WATCH_LIST.REQUEST, addProductToWatchList);
}

export function* watchRemoveProductFromWatchList() {
  yield* takeEvery(
    REMOVE_PRODUCT_FROM_WATCH_LIST.REQUEST,
    removeProductFromWatchList,
  );
}

export function* watchLockProduct() {
  yield* takeEvery(LOCK_PRODUCT.REQUEST, lockProduct);
}

export function* watchUnlockProduct() {
  yield* takeEvery(UNLOCK_PRODUCT.REQUEST, unLockProduct);
}

export function* watchGetLocksForCategory() {
  yield* takeEvery(FETCH_LOCKS_FOR_CATEGORY.REQUEST, getLocksForCategory);
}

export function* watchGetContentSlots() {
  yield* takeEvery(GET_CONTENT_SLOTS.REQUEST, getContentSlots);
}

export function* watchAddContentSlot() {
  yield* takeEvery(ADD_CONTENT_SLOT.REQUEST, addContentSlot);
}

export function* watchUpdateContentSlot() {
  yield* takeEvery(UPDATE_CONTENT_SLOT.REQUEST, updateContentSlot);
}

export function* watchThrowLockedProductWarning() {
  yield* takeEvery(THROW_LOCKED_PRODUCT_WARNING, throwLockedProductWarning);
}

export function* watchThrowContentSlotWarning() {
  yield* takeEvery(THROW_CONTENT_SLOT_WARNING, throwContentSlotWarning);
}

export function* watchDeleteContentSlot() {
  yield* takeEvery(DELETE_CONTENT_SLOT.REQUEST, deleteContentSlot);
}

export function* watchGetPinnedProducts() {
  yield* takeEvery(GET_PINNED_PRODUCTS.REQUEST, getPinnedProducts);
}

export function* watchSetPinnedProducts() {
  yield* takeEvery(SET_PINNED_PRODUCTS.REQUEST, setPinnedProducts);
}
