import {
  FETCH_PRODUCT_LIST,
  LoadProductsFromCategoryResponseAction,
  ProductSequenceData,
  UPDATE_PRODUCT_LIST_IN_STATE,
  updateProductListInStateAction,
  UPDATE_PRODUCT_LIST_SEQUENCE_MODE,
  updateProductListSequenceModeAction,
  RESET_PRODUCT_LIST,
  RESET_PRODUCT_SEQUENCE,
  UPDATE_NUMBER_OF_PINNED_PRODUCTS,
  UpdateNumberOfPinnedProductsAction,
  UPDATE_PINNED_PRODUCTS,
  SET_PINNED_PRODUCTS_MODE,
  SetPinnedProductsModeAction,
  SET_SORTING_RULE,
  SetSortingRuleAction,
  BusinessRulesListDetails,
  FETCH_SORTING_LIST,
  LoadSortingRulesListResponseAction,
  FETCH_SORTED_PRODUCTS,
  FETCH_PRODUCT_IDS,
  LOAD_SORTED_PRODUCTS,
  UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC,
  UPDATE_PRODUCT_SEQUENCE_LIST_SYNC,
  GET_PRODUCT_SEQUENCE_LIST,
  FETCH_PRODUCT,
  UPDATE_PRODUCT_SEQUENCE,
  WatchedProductListData,
  FETCH_WATCHED_PRODUCT_LIST,
  WatchedProductListResponse,
  ADD_PRODUCT_TO_WATCH_LIST,
  REMOVE_PRODUCT_FROM_WATCH_LIST,
  addRemoveProductInWatchListPayload,
  REVERT_PRODUCT_SEQUENCE,
  ProductIdsData,
  ProductListSequenceResponse,
  RESET_SEQUENCE_REQUESTED_BY_BOOST_BURY,
  FETCH_LOCKS_FOR_CATEGORY,
  LocksInCategory,
  LocksInCategoryResponse,
  LOCK_PRODUCT_COMPLETED,
  UNLOCK_PRODUCT_COMPLETED,
  UnLockProductResponsePayload,
  RESET_LOCKS_IN_CATEGORY,
  lockUnLockProductPayload,
  RESET_SAVED_LOCKED_UNLOCKED_PRODUCTS,
  SET_LOCKED_UNLOCKED_PRODUCTS,
  GET_CONTENT_SLOTS,
  ContentSlot,
  ContentSlotsResponse,
  RESET_CONTENT_SLOTS,
  updateContentSlotStateResponse,
  UPDATE_CONTENT_SLOT_STATE,
  DELETE_CONTENT_SLOT_COMPLETED,
  deleteContentSlotPayload,
  SET_PINNED_UNPINNED_PRODUCTS,
  RESET_STORED_PINNED_UNPINNED_PRODUCT_IDS,
  RESET_PINNED_UNPINNED_PRODUCTS,
  SET_PINNED_PRODUCTS,
  GET_PINNED_PRODUCTS,
  getPinnedProductsResponse,
} from "./ProductListTypes";
import { SEQUENCE_MODE_TYPE } from "../../utils/Constants";
import {
  ADD_PRODUCT_IN_CATEGORY,
  LoadSortedProductsAction,
  REMOVE_PRODUCT_IN_CATEGORY,
  SortedProductScore,
} from "../product/ProductTypes";
import { DELETE_PRODUCT_BY_CATEGORIES } from "../remove-product-categories/RemoveProductCategoriesTypes";

export interface ProductListState {
  isFetched: boolean;
  isFetching: boolean; // flag to indicate that product list is loading (or refreshing) to be used to show the loading progress/spinner
  isSequenceUpdated: boolean;
  isSequenceLoading: boolean;
  isSequenceSaving: boolean; // true if there is an inflight request to save the sequence, to be used to show a spinner
  isSquenceUpdateRequest: boolean;
  productSequences: ProductSequenceData[]; // why do we need this, when we have ProductReducer to store products?
  editSequenceList: ProductSequenceData[]; // why do we need this, when we have ProductReducer to store products?
  sequenceMode: number;
  pinnedProducts: PinnedProducts;
  isPinnedProductsMode: boolean;
  sortingRule: string | null;
  sortingRulesList: BusinessRulesListDetails[];
  isSortingRulesListLoaded: boolean;
  isProductIdsFetched: boolean;
  isFetchingProductIds: boolean;
  productIds: string[]; // the saved product sequence (should probably rename to savedSequence)
  categoryId?: string;
  isRefreshingAllProducts?: boolean;
  isFetchingSortedProducts: boolean;
  requestId: string | null;
  addingRemovingProductIdsList?: string[];
  isUpdateFunctionInvoked?: boolean;
  updatedStateProductIds: string[]; // the working (i.e. unsaved) product sequence (should probably rename to workingSequence)
  isProductAddedToCategoryFromClipBoard?: boolean;
  isUpdateProductSequenceError?: boolean;
  watchedProductList: WatchedProductListData[];
  originalEditSequenceList: ProductSequenceData[];
  originalProductSequences: ProductSequenceData[];
  originalUpdatedStateProductIds: string[];
  productIdsData: ProductIdsData;
  total?: number;
  productSequenceList: ProductListSequenceResponse;
  isBoostBurySequenceRequested: boolean;
  locksInCategory: LocksInCategory[];
  savedLockedProductsInCategory: lockUnLockProductPayload[];
  savedUnlockedProductsInCategory: lockUnLockProductPayload[];
  locksRemoveInCategory: LocksInCategory[];
  contentSlots: ContentSlot[];
  pinnedProductIds: string[];
  storedPinnedProductIds: string[];
  storedUnpinnedProductIds: string[];
  originalStoredPinnedProductIds: string[];
}

const initialState: ProductListState = {
  isFetched: false,
  isFetching: false,
  isSequenceUpdated: false,
  isSequenceLoading: false,
  isSequenceSaving: false,
  isSquenceUpdateRequest: false,
  productSequences: [],
  editSequenceList: [],
  sequenceMode: SEQUENCE_MODE_TYPE.VIEW,
  isPinnedProductsMode: false,
  pinnedProducts: {
    numberOfPinnedProducts: 0,
    pinnedProductIds: [],
  },
  sortingRule: null,
  sortingRulesList: [],
  isSortingRulesListLoaded: false,
  isProductIdsFetched: false,
  isFetchingProductIds: false,
  productIds: [],
  categoryId: "",
  isRefreshingAllProducts: false,
  isFetchingSortedProducts: false,
  requestId: null,
  addingRemovingProductIdsList: [],
  isUpdateFunctionInvoked: false,
  updatedStateProductIds: [],
  isProductAddedToCategoryFromClipBoard: false,
  isUpdateProductSequenceError: false,
  watchedProductList: [],
  originalEditSequenceList: [],
  originalProductSequences: [],
  originalUpdatedStateProductIds: [],
  productIdsData: {
    productIds: [],
    categoryId: "",
    next: "",
    count: 0,
    total: 0,
  },
  productSequenceList: {
    accountId: "",
    requestId: "",
    response: {
      statusCode: 0,
      body: "",
    },
  },
  isBoostBurySequenceRequested: false,
  locksInCategory: [],
  savedLockedProductsInCategory: [],
  savedUnlockedProductsInCategory: [],
  locksRemoveInCategory: [],
  contentSlots: [],
  pinnedProductIds: [],
  storedPinnedProductIds: [],
  storedUnpinnedProductIds: [],
  originalStoredPinnedProductIds: [],
};

export interface PinnedProducts {
  numberOfPinnedProducts: number;
  pinnedProductIds: string[];
}

export const ProductListReducer = (
  state: ProductListState = initialState,
  action,
): ProductListState => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST.START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PRODUCT_LIST.STOP: {
      return {
        ...state,
        isFetching: false,
        originalEditSequenceList: state.editSequenceList,
        originalProductSequences: state.productSequences,
      };
    }
    case FETCH_PRODUCT_LIST.SUCCESS: {
      const fetchAction = action as LoadProductsFromCategoryResponseAction;

      return {
        ...state,
        isFetched: true,
        sequenceMode:
          action.payload.isRefreshingAllProducts === true
            ? state.sequenceMode
            : 1,
        categoryId: fetchAction.payload.categoryId,
        isRefreshingAllProducts: false,
      };
    }
    case FETCH_SORTED_PRODUCTS.REQUEST:
    case LOAD_SORTED_PRODUCTS:
      return {
        ...state,
        isFetchingSortedProducts: true,
      };
    case DELETE_PRODUCT_BY_CATEGORIES.DONE: {
      const productIdToBeRemoved = action.payload.productId;
      const productList = [...state.productSequences].filter(
        (prod) => productIdToBeRemoved !== prod.productId,
      );
      const updatedListIds = productList.map((item) => item.productId);
      const updatedProductIdsData = {
        ...state.productIdsData,
        productIds: updatedListIds,
        total: updatedListIds.length,
      };
      return {
        ...state,
        productSequences: productList,
        editSequenceList: productList,
        productIds: updatedListIds,
        originalEditSequenceList: productList,
        originalProductSequences: productList,
        productIdsData: updatedProductIdsData,
      };
    }
    case FETCH_SORTED_PRODUCTS.SUCCESS: {
      const fetchedProductActionObj = action as LoadSortedProductsAction;
      const productsArray = fetchedProductActionObj.payload.products;

      //calculate product scores
      const calculateProductScores = (products: SortedProductScore[]) => {
        const scores = {};
        products.forEach((p) => {
          const scoreSum = Array.isArray(p.score)
            ? p.score.some((score) => score < 0)
              ? p.score.find((score) => score < 0)
              : p.score.reduce((sum, a) => sum + a, 0)
            : p.score;
          scores[p.productId] = scoreSum;
        });
        return scores;
      };

      // get scores for products other than the negative score if we had any
      const getOtherScoresSum = (product: any) => {
        const scores = Array.isArray(product?.score)
          ? product?.score.filter((score) => score >= 0)
          : product?.score;

        return Array.isArray(scores)
          ? scores.reduce((sum, score) => sum + score, 0)
          : scores;
      };

      // Sorting function for products
      const sortProductsByScore = (
        a: ProductSequenceData,
        b: ProductSequenceData,
        productsWithScore: any,
        productsArray: SortedProductScore[],
      ) => {
        const scoreA = productsWithScore[a.productId];
        const scoreB = productsWithScore[b.productId];

        // If both scores are negative, sort by other scores
        if (scoreA < 0 && scoreB < 0) {
          const productA = productsArray.find(
            (p) => p.productId === a.productId,
          );
          const productB = productsArray.find(
            (p) => p.productId === b.productId,
          );

          const sumA = getOtherScoresSum(productA);
          const sumB = getOtherScoresSum(productB);

          return sumB - sumA;
        }

        // If only one score is negative, it should go last
        if (scoreA < 0) return 1;
        if (scoreB < 0) return -1;

        // Normal sorting for non-negative scores
        return scoreB - scoreA;
      };

      const productsWithScore = calculateProductScores(productsArray);
      const productSeqCopy = [...state.productSequences];

      // Check if there are any locks for the current category
      const locksForCurrentCategory = state.locksInCategory.filter(
        (lock) => lock.categoryId === state.categoryId,
      );

      // If there are no locks, sort all products
      if (locksForCurrentCategory.length === 0) {
        const sortedProducts = productSeqCopy.sort((a, b) =>
          sortProductsByScore(a, b, productsWithScore, productsArray),
        );

        const productIdsData = sortedProducts.map(
          (product) => product.productId,
        );
        const sequencedData = sortedProducts.map((item, index) => ({
          ...item,
          sequence: index + 1,
        }));

        return {
          ...state,
          originalEditSequenceList: state.editSequenceList,
          originalProductSequences: state.productSequences,
          productSequences: sequencedData,
          editSequenceList: sequencedData,
          sequenceMode: 2,
          isFetchingSortedProducts: false,
          updatedStateProductIds: productIdsData,
        };
      }

      // Original locked products logic
      const lockedPositions = new Map(
        locksForCurrentCategory.map((lock) => [
          lock.position - 1,
          lock.productId,
        ]),
      );

      // Separate and sort unlocked products
      const unlockedProducts = productSeqCopy
        .filter(
          (product) =>
            !Array.from(lockedPositions.values()).includes(product.productId),
        )
        ?.sort((a, b) =>
          sortProductsByScore(a, b, productsWithScore, productsArray),
        );

      // Merge locked and unlocked products
      const finalSortedProducts: ProductSequenceData[] = [];
      let unlockedIndex = 0;

      for (let i = 0; i < productSeqCopy.length; i++) {
        const lockedProductId = lockedPositions.get(i);
        if (lockedProductId) {
          // Insert locked product at its original position
          const lockedProduct = productSeqCopy.find(
            (p) => p.productId === lockedProductId,
          );
          if (lockedProduct) {
            finalSortedProducts[i] = lockedProduct;
          }
        } else {
          // Insert next unlocked product
          finalSortedProducts[i] = unlockedProducts[unlockedIndex++];
        }
      }

      const productIdsData = finalSortedProducts.map(
        (product) => product.productId,
      );
      const sequencedData = finalSortedProducts.map((item, index) => ({
        ...item,
        sequence: index + 1,
      }));

      return {
        ...state,
        originalEditSequenceList: state.editSequenceList,
        originalProductSequences: state.productSequences,
        productSequences: sequencedData,
        editSequenceList: sequencedData,
        sequenceMode: 2,
        isFetchingSortedProducts: false,
        updatedStateProductIds: productIdsData,
      };
    }
    case FETCH_SORTED_PRODUCTS.FAILURE: {
      return {
        ...state,
        isFetchingSortedProducts: false,
      };
    }
    case FETCH_PRODUCT_LIST.REQUEST: {
      const payload = action?.payload;
      return {
        ...state,
        isFetched: false,
        isRefreshingAllProducts: payload?.isRefreshingAllProducts
          ? true
          : false,
      };
    }
    case FETCH_PRODUCT_LIST.FAILURE: {
      return {
        ...state,
        isFetched: true,
        isRefreshingAllProducts: false,
      };
    }
    case UPDATE_PRODUCT_LIST_IN_STATE: // updates the working sequence after a drag and drop, or sort
      let updateAction = action as updateProductListInStateAction;
      const sortedProducts = updateAction.payload.products.map((prod, idx) => ({
        ...prod,
        sequence: idx + 1,
      }));
      const seqProductIds = updateAction.payload.products.map(
        (product) => product.productId,
      );
      return {
        ...state,
        editSequenceList: sortedProducts,
        productSequences: updateAction.payload.isSaveAction
          ? sortedProducts
          : state.productSequences,
        updatedStateProductIds: seqProductIds,
      };
    case UPDATE_PRODUCT_LIST_SEQUENCE_MODE:
      let sequenceModeAction = action as updateProductListSequenceModeAction;
      let newState = {
        ...state,
        sequenceMode: sequenceModeAction.sequenceMode
          ? sequenceModeAction.sequenceMode
          : SEQUENCE_MODE_TYPE.VIEW,
      };
      if (sequenceModeAction.sequenceMode === SEQUENCE_MODE_TYPE.VIEW) {
        newState = {
          ...newState,
          editSequenceList: state.productSequences,
          isSequenceUpdated: true,
          pinnedProducts: {
            ...state.pinnedProducts,
            numberOfPinnedProducts:
              state.pinnedProducts.pinnedProductIds.length,
          },
        };
      }
      return newState;

    case UPDATE_PINNED_PRODUCTS.SUCCESS: {
      const pinnedProductIds = state.editSequenceList
        .map((product) => product.productId)
        .slice(0, state.pinnedProducts.numberOfPinnedProducts);
      const pinnedProducts = {
        numberOfPinnedProducts: pinnedProductIds.length,
        pinnedProductIds,
      };
      return {
        ...state,
        pinnedProducts,
      };
    }

    case UPDATE_NUMBER_OF_PINNED_PRODUCTS: {
      const updateNumberOfPinnedProductsAction =
        action as UpdateNumberOfPinnedProductsAction;
      const numberOfPinnedProducts =
        updateNumberOfPinnedProductsAction.payload.numberOfPinnedProducts;
      const pinnedProducts = {
        ...state.pinnedProducts,
        numberOfPinnedProducts,
      };
      return {
        ...state,
        pinnedProducts,
      };
    }

    case SET_PINNED_PRODUCTS_MODE: {
      const setPinnedProductsModeActionAction =
        action as SetPinnedProductsModeAction;
      const isPinnedProductsMode =
        setPinnedProductsModeActionAction.isPinnedProductsMode;
      return {
        ...state,
        isPinnedProductsMode,
      };
    }

    case SET_SORTING_RULE: {
      const setSortingRuleAction = action as SetSortingRuleAction;
      const sortingRule = setSortingRuleAction.rule;
      return {
        ...state,
        sortingRule,
      };
    }

    case RESET_PRODUCT_SEQUENCE: {
      return {
        ...state,
        isSequenceUpdated: false,
      };
    }

    case RESET_PRODUCT_LIST: {
      const pinnedProducts = state.pinnedProducts;
      const watchedProductList = [...state.watchedProductList];
      return {
        ...initialState,
        pinnedProducts,
        watchedProductList,
      };
    }
    case FETCH_SORTING_LIST.SUCCESS: {
      const sortingRulesListAction =
        action as LoadSortingRulesListResponseAction;
      const sortingRulesList = sortingRulesListAction.payload.results;
      return {
        ...state,
        sortingRulesList,
        isSortingRulesListLoaded: true,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE: {
      return {
        ...state,
        isUpdateProductSequenceError: false,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST_SYNC.REQUEST: {
      return {
        ...state,
        isUpdateProductSequenceError: false,
        isSequenceSaving: true,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST_SYNC.SUCCESS: {
      let stateProductIds: string[] = [];
      if (state.updatedStateProductIds.length > 0) {
        //Displaying updated products when we do the refresh all after saving the product sorting/sequence
        stateProductIds = [...Object.values(state.updatedStateProductIds)];
      } else {
        stateProductIds = [...Object.values(state.productIds)];
      }
      return {
        ...state,
        isUpdateProductSequenceError: false,
        isSequenceSaving: false,
        productIds: stateProductIds,
        sequenceMode: 1,
        originalEditSequenceList: state.editSequenceList,
        originalProductSequences: state.productSequences,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST_SYNC.FAILURE: {
      return {
        ...state,
        isUpdateProductSequenceError: true,
        isSequenceSaving: false,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC.REQUEST: {
      return {
        ...state,
        isUpdateProductSequenceError: false,
        isSequenceLoading: false,
        isBoostBurySequenceRequested: true,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC.SUCCESS: {
      let stateProductIds: string[] = [];
      if (state.updatedStateProductIds.length > 0) {
        //Displaying updated products when we do the refresh all after saving the product sorting/sequence
        stateProductIds = [...Object.values(state.updatedStateProductIds)];
      } else {
        stateProductIds = [...Object.values(state.productIds)];
      }

      return {
        ...state,
        requestId: action.payload.requestId,
        isUpdateFunctionInvoked: true,
        productIds: stateProductIds,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST_ASYNC.FAILURE: {
      return {
        ...state,
        isUpdateProductSequenceError: true,
        isUpdateFunctionInvoked: false,
        isBoostBurySequenceRequested: false,
      };
    }
    case GET_PRODUCT_SEQUENCE_LIST.SUCCESS: {
      const productSequenceRes = action.payload;
      return {
        ...state,
        isSequenceLoading: false,
        productSequenceList: productSequenceRes,
        isSquenceUpdateRequest: false,
      };
    }
    case GET_PRODUCT_SEQUENCE_LIST.DONE: {
      return {
        ...state,
        isSequenceLoading: false,
        isSquenceUpdateRequest: true,
        isUpdateFunctionInvoked: false,
        sequenceMode: 1,
        originalEditSequenceList: state.editSequenceList,
        originalProductSequences: state.productSequences,
      };
    }
    case GET_PRODUCT_SEQUENCE_LIST.FAILURE: {
      return {
        ...state,
        isUpdateFunctionInvoked: false,
      };
    }
    case FETCH_PRODUCT_IDS.REQUEST: {
      const payload = action.payload;
      const Ids = payload.productIds ? state.productIds : [];
      return {
        ...state,
        productIds: payload.next ? state.productIds : Ids,
        productSequences: [],
        editSequenceList: [],
        isFetchingProductIds: payload.productIds ? false : true,
        isProductIdsFetched: false,
        isRefreshingAllProducts: payload.isRefreshingAllProducts ? true : false,
        total: undefined,
      };
    }
    case FETCH_PRODUCT_IDS.SUCCESS: {
      const payload: ProductIdsData = action.payload;
      const productIdData = payload.productIds;
      const pIds =
        state.productIds.length > 0
          ? state.productIds.concat(productIdData)
          : productIdData;
      return {
        ...state,
        productIds: pIds,
        productSequences: [
          ...state.productSequences,
          ...pIds.map((productId) => ({ productId })),
        ],
        editSequenceList: [
          ...state.editSequenceList,
          ...pIds.map((productId) => ({ productId })),
        ],
        updatedStateProductIds: pIds,
        isProductIdsFetched: true,
        isFetchingProductIds: false,
        isRefreshingAllProducts: false,
        productIdsData: payload,
        total: payload.total,
      };
    }
    case FETCH_PRODUCT_IDS.FAILURE: {
      return {
        ...state,
        isProductIdsFetched: false,
        isFetchingProductIds: false,
        isRefreshingAllProducts: false,
      };
    }
    case ADD_PRODUCT_IN_CATEGORY.REQUEST: {
      const productId = action.payload.productId;
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList?.push(productId);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
      };
    }
    case ADD_PRODUCT_IN_CATEGORY.FAILURE:
    case ADD_PRODUCT_IN_CATEGORY.SUCCESS: {
      const productId = action.payload.productId;
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList = updatedProductIdsList?.filter(
        (id) => id !== productId,
      );
      let updatedProductIds = [...state.productIds];
      updatedProductIds.push(productId);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
        productIds: updatedProductIds,
        updatedStateProductIds: updatedProductIds,
      };
    }
    case REMOVE_PRODUCT_IN_CATEGORY.REQUEST: {
      const productId = action.payload.productId;
      const updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList?.push(productId);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
      };
    }
    case REMOVE_PRODUCT_IN_CATEGORY.SUCCESS: {
      const productIdToBeRemoved = action.payload.productId;
      const removeFromCategory = action.payload.isFromCategory;

      const isPublishedAndIsCategory = action.payload.isPublished;

      const productSequencesData = [...state.productSequences];
      const updatedProductList = productSequencesData.filter(
        (product) => product.productId !== productIdToBeRemoved,
      );
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList = updatedProductIdsList?.filter(
        (id) => id !== productIdToBeRemoved,
      );

      return {
        ...state,
        productSequences: updatedProductList,
        editSequenceList: updatedProductList,
        productIds: isPublishedAndIsCategory
          ? state.productIds.filter((id) => id !== productIdToBeRemoved)
          : !removeFromCategory
            ? state.productIds
            : state.productIds.filter((id) => id !== productIdToBeRemoved),
        addingRemovingProductIdsList: updatedProductIdsList,
        originalEditSequenceList: updatedProductList,
        originalProductSequences: updatedProductList,
      };
    }
    case REMOVE_PRODUCT_IN_CATEGORY.FAILURE: {
      const productIdToBeRemoved = action.payload.productId;
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList?.filter((id) => id !== productIdToBeRemoved);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
      };
    }
    case FETCH_PRODUCT.REQUEST: {
      const productData = action?.payload;
      return {
        ...state,
        isProductAddedToCategoryFromClipBoard:
          !!productData.isProductAddedFromClipBoard,
      };
    }
    case FETCH_PRODUCT.SUCCESS: {
      const productData = action?.payload;
      const updatedProductSequencesData = [...state.productSequences];
      const updatedProductIds =
        state.updatedStateProductIds.length > 0
          ? [...state.updatedStateProductIds]
          : [...state.productIds];
      if (productData && productData.productId) {
        //Check if the product is not removedProduct.
        if (!productData.isProductRemoved) {
          const existsInSequences = updatedProductSequencesData.some(
            (item) => item.productId === productData.productId,
          );
          //handle Add product case if not exist in sequence
          if (!existsInSequences) {
            updatedProductSequencesData.push({
              sequence: productData?.cachedProduct?.sequence,
              productId: productData?.productId,
            });
          }
          if (
            state.isProductAddedToCategoryFromClipBoard &&
            !updatedProductIds.includes(productData.productId)
          ) {
            updatedProductIds.push(productData.productId);
          }
        } else {
          //Remove product from sequence and updatedProductIds
          const indexToRemove = updatedProductSequencesData.findIndex(
            (item) => item.productId === productData.productId,
          );
          if (indexToRemove !== -1) {
            updatedProductSequencesData.splice(indexToRemove, 1);
          }
          const idIndexToRemove = updatedProductIds.indexOf(
            productData.productId,
          );
          if (idIndexToRemove !== -1) {
            updatedProductIds.splice(idIndexToRemove, 1);
          }
        }
      }
      let updatedProductIdsData = { ...state.productIdsData };
      if (updatedProductIds.length !== state.productIdsData.total) {
        updatedProductIdsData.productIds = updatedProductIds;
        updatedProductIdsData.total = updatedProductIds.length;
      }

      return {
        ...state,
        productSequences: updatedProductSequencesData,
        editSequenceList: updatedProductSequencesData,
        productIds: updatedProductIds,
        updatedStateProductIds: updatedProductIds,
        originalEditSequenceList: updatedProductSequencesData,
        originalProductSequences: updatedProductSequencesData,
        productIdsData: updatedProductIdsData,
      };
    }
    case REVERT_PRODUCT_SEQUENCE: {
      return {
        ...state,
        editSequenceList: state.originalEditSequenceList,
        productSequences: state.originalProductSequences,
        updatedStateProductIds: state.productIds,
        sequenceMode: 1,
      };
    }
    case FETCH_WATCHED_PRODUCT_LIST.SUCCESS: {
      const productListResponse = action.payload as WatchedProductListResponse;
      return {
        ...state,
        watchedProductList: productListResponse.results,
      };
    }
    case ADD_PRODUCT_TO_WATCH_LIST.SUCCESS: {
      const addProductToWatchListResponse =
        action.payload as addRemoveProductInWatchListPayload;
      const watchedProducts = [
        ...state.watchedProductList,
        {
          productId: addProductToWatchListResponse.productId,
          storeId: addProductToWatchListResponse.storeId,
          watchers: [addProductToWatchListResponse.watcher],
        },
      ];
      return {
        ...state,
        watchedProductList: watchedProducts,
      };
    }
    case REMOVE_PRODUCT_FROM_WATCH_LIST.SUCCESS: {
      const removeProductFromWatchListResponse =
        action.payload as addRemoveProductInWatchListPayload;
      const watchedProducts = [...state.watchedProductList]?.filter(
        (watchedProduct) =>
          watchedProduct.productId !==
          removeProductFromWatchListResponse.productId,
      );
      return {
        ...state,
        watchedProductList: watchedProducts,
      };
    }
    case RESET_SEQUENCE_REQUESTED_BY_BOOST_BURY: {
      return {
        ...state,
        isBoostBurySequenceRequested: false,
      };
    }
    case FETCH_LOCKS_FOR_CATEGORY.SUCCESS: {
      const locksInCategoryResponse = action.payload as LocksInCategoryResponse;
      const locksInCategory =
        state.locksInCategory.length > 0
          ? locksInCategoryResponse.results?.filter(
              (newLock) =>
                !state.locksInCategory.some(
                  (existingLock) =>
                    existingLock.productId === newLock.productId &&
                    existingLock.catalogId === newLock.catalogId &&
                    existingLock.categoryId === newLock.categoryId,
                ),
            )
          : locksInCategoryResponse.results;
      return {
        ...state,
        locksInCategory: [...state.locksInCategory, ...locksInCategory],
      };
    }
    case LOCK_PRODUCT_COMPLETED: {
      const lockProductResponse = action.payload as LocksInCategory;
      const existingLockIndex = state.locksInCategory.findIndex(
        (lock) =>
          lock.catalogId === lockProductResponse.catalogId &&
          lock.categoryId === lockProductResponse.categoryId &&
          lock.position === lockProductResponse.position,
      );

      let updatedLocks: LocksInCategory[];
      if (existingLockIndex !== -1) {
        // Update existing lock
        updatedLocks = [...state.locksInCategory];
        updatedLocks[existingLockIndex] = lockProductResponse;
      } else {
        // Insert new lock
        updatedLocks = [...state.locksInCategory, lockProductResponse];
      }

      const updatedLocksRemoveInCategory =
        state.locksRemoveInCategory?.filter(
          (lock) =>
            !updatedLocks?.some(
              (newLock) => newLock.productId === lock.productId,
            ),
        ) ?? [];
      return {
        ...state,
        locksInCategory: updatedLocks,
        locksRemoveInCategory: updatedLocksRemoveInCategory,
      };
    }
    case UNLOCK_PRODUCT_COMPLETED: {
      const unlockProductResponse =
        action.payload as UnLockProductResponsePayload;
      const locksInCategory = state.locksInCategory?.filter(
        (lock) =>
          !(
            lock.catalogId === unlockProductResponse.catalogId &&
            lock.categoryId === unlockProductResponse.categoryId &&
            lock.position === unlockProductResponse.position
          ),
      );
      // Check if unlock response already exists in locksRemoveInCategory
      const isDuplicate = state.locksRemoveInCategory?.some(
        (lock) =>
          lock.catalogId === unlockProductResponse.catalogId &&
          lock.categoryId === unlockProductResponse.categoryId &&
          lock.position === unlockProductResponse.position,
      );

      const locksRemoveInCategory = isDuplicate
        ? state.locksRemoveInCategory
        : [...state.locksRemoveInCategory, unlockProductResponse];

      return {
        ...state,
        locksInCategory: locksInCategory,
        locksRemoveInCategory: locksRemoveInCategory,
      };
    }
    case RESET_LOCKS_IN_CATEGORY: {
      return {
        ...state,
        locksInCategory: [],
        locksRemoveInCategory: [],
        savedLockedProductsInCategory: [],
        savedUnlockedProductsInCategory: [],
      };
    }
    case SET_LOCKED_UNLOCKED_PRODUCTS: {
      const lockedUnlockedProducts = action.payload;

      // Check if product exists in either list before filtering
      const wasExistingInLockedListBefore =
        state.savedLockedProductsInCategory?.some(
          (product) =>
            product.productId === lockedUnlockedProducts.productId &&
            product.categoryId === lockedUnlockedProducts.categoryId,
        );
      const wasLockedInCategory = state.locksInCategory.some(
        (lock) =>
          lock.productId === lockedUnlockedProducts.productId &&
          lock.categoryId === lockedUnlockedProducts.categoryId,
      );

      const wasExistingInUnlockedListBefore =
        state.savedUnlockedProductsInCategory?.some(
          (product) =>
            product.productId === lockedUnlockedProducts.productId &&
            product.categoryId === lockedUnlockedProducts.categoryId,
        );
      const wasUnlockedInCategory = state.locksRemoveInCategory?.some(
        (lock) =>
          lock.productId === lockedUnlockedProducts.productId &&
          lock.categoryId === lockedUnlockedProducts.categoryId,
      );

      // Filter out the product from both lists
      const filteredLockedProducts =
        state.savedLockedProductsInCategory?.filter(
          (product) =>
            !(
              product.productId === lockedUnlockedProducts.productId &&
              product.categoryId === lockedUnlockedProducts.categoryId
            ),
        ) ?? [];
      const filteredUnlockedProducts =
        state.savedUnlockedProductsInCategory?.filter(
          (product) =>
            !(
              product.productId === lockedUnlockedProducts.productId &&
              product.categoryId === lockedUnlockedProducts.categoryId
            ),
        ) ?? [];

      // Only add to locked list if it wasn't just removed from unlocked list or was unlocked in database
      if (
        lockedUnlockedProducts.isLocked &&
        (!wasExistingInUnlockedListBefore || wasUnlockedInCategory)
      ) {
        filteredLockedProducts.push(lockedUnlockedProducts);
      }
      // Only add to unlocked list if it wasn't just removed from locked list or was locked in database
      else if (
        !lockedUnlockedProducts.isLocked &&
        (!wasExistingInLockedListBefore || wasLockedInCategory)
      ) {
        filteredUnlockedProducts.push(lockedUnlockedProducts);
      }
      let updatedPinnedProducts: string[] = [...state.storedPinnedProductIds];
      if (filteredUnlockedProducts.length > 0) {
        const pinnedProducts = [
          ...new Set([
            ...state.pinnedProductIds,
            ...state.storedPinnedProductIds,
          ]),
        ]?.filter((id) => !state.storedUnpinnedProductIds.includes(id));

        // Filter out products that are already pinned
        const nonPinnedUnlockedProducts = filteredUnlockedProducts.filter(
          (product) => !pinnedProducts.includes(product.productId),
        );

        if (nonPinnedUnlockedProducts.length > 0) {
          // Find positions of non-pinned unlocked products in sequence
          const unlockedPositions = nonPinnedUnlockedProducts
            .map((unlockedProduct) => {
              const index = state.productSequences.findIndex(
                (product) => product.productId === unlockedProduct.productId,
              );
              return index !== -1 ? index : null;
            })
            .filter((pos) => pos !== null);

          if (unlockedPositions.length > 0) {
            // Find the last position of unlocked products
            const lastUnlockedPosition = Math.max(...unlockedPositions);

            // Check for pinned products after the last unlocked position
            const pinnedProductsAfter = state.productSequences
              .slice(lastUnlockedPosition + 1)
              .filter((product) => pinnedProducts?.includes(product.productId));
            //if there are pinned products after the last unlocked position, then we need to add the unlocked product to the pinned list
            if (pinnedProductsAfter.length > 0) {
              const convertingToPinnedProductIds =
                nonPinnedUnlockedProducts.map((product) => product.productId);
              updatedPinnedProducts = [
                ...new Set([
                  ...state.storedPinnedProductIds,
                  ...state.pinnedProductIds,
                  ...convertingToPinnedProductIds,
                ]),
              ];
            }
          }
        }
      } else {
        updatedPinnedProducts = [...state.originalStoredPinnedProductIds];
      }

      return {
        ...state,
        savedLockedProductsInCategory: filteredLockedProducts,
        savedUnlockedProductsInCategory: filteredUnlockedProducts,
        storedPinnedProductIds: updatedPinnedProducts,
        sequenceMode:
          filteredLockedProducts.length ||
          filteredUnlockedProducts.length > 0 ||
          updatedPinnedProducts?.filter(
            (id) => !state.pinnedProductIds?.includes(id),
          )?.length > 0 ||
          state.storedUnpinnedProductIds.length > 0
            ? SEQUENCE_MODE_TYPE.EDIT
            : SEQUENCE_MODE_TYPE.VIEW,
      };
    }
    case RESET_SAVED_LOCKED_UNLOCKED_PRODUCTS: {
      return {
        ...state,
        savedLockedProductsInCategory: [],
        savedUnlockedProductsInCategory: [],
        sequenceMode: SEQUENCE_MODE_TYPE.VIEW,
      };
    }
    case GET_CONTENT_SLOTS.SUCCESS: {
      const contentSlotsResponse = action.payload as ContentSlotsResponse;
      const contentSlots = contentSlotsResponse.slots?.map((slot) => ({
        ...slot,
        type: "slot",
      }));
      return {
        ...state,
        contentSlots: contentSlots,
      };
    }
    case RESET_CONTENT_SLOTS: {
      return {
        ...state,
        contentSlots: [],
      };
    }
    case UPDATE_CONTENT_SLOT_STATE: {
      const updateContentSlotStateResponse =
        action.payload as updateContentSlotStateResponse;
      const existingContentSlot = state.contentSlots?.find(
        (slot) => slot.slotId === updateContentSlotStateResponse.slotId,
      );
      let newContentSlot: ContentSlot;
      if (existingContentSlot) {
        newContentSlot = {
          ...existingContentSlot,
          position: updateContentSlotStateResponse.position,
          type: "slot",
        };
      } else {
        newContentSlot = {
          slotId: updateContentSlotStateResponse.slotId,
          position: updateContentSlotStateResponse.position,
          type: "slot",
        };
      }

      // Filter out any existing slots with the same slotId before adding the new one
      const filteredContentSlots = state.contentSlots?.filter(
        (slot) => slot.slotId !== updateContentSlotStateResponse.slotId,
      );

      return {
        ...state,
        contentSlots: [...filteredContentSlots, newContentSlot],
      };
    }
    case DELETE_CONTENT_SLOT_COMPLETED: {
      const deleteContentSlotResponse =
        action.payload as deleteContentSlotPayload;
      const filteredContentSlots = state.contentSlots?.filter(
        (slot) => slot.slotId !== deleteContentSlotResponse.slotId,
      );
      return {
        ...state,
        contentSlots: filteredContentSlots,
      };
    }
    case SET_PINNED_UNPINNED_PRODUCTS: {
      const { productId, isPinned, catalogId, categoryId } = action.payload;
      const existingStoredPinnedProducts = state.storedPinnedProductIds;
      const pinnedProductsFromDB = [...state.pinnedProductIds];
      let unpinnedProducts = [...state.storedUnpinnedProductIds];

      // Check if this product was previously modified in the current session
      const wasPinnedInRedux = existingStoredPinnedProducts.includes(productId);
      const wasUnpinnedInRedux = unpinnedProducts.includes(productId);
      const isPinnedInDB = pinnedProductsFromDB.includes(productId);

      if (
        (wasPinnedInRedux && !isPinned && !isPinnedInDB) ||
        (wasUnpinnedInRedux && isPinned && isPinnedInDB)
      ) {
        unpinnedProducts = unpinnedProducts.filter((id) => id !== productId);
        const updatedStoredPinned = existingStoredPinnedProducts.filter(
          (id) => id !== productId,
        );

        // Get current sequence excluding the product being reverted
        const currentSequenceWithoutProduct = state.productSequences.filter(
          (product) => product.productId !== productId,
        );

        // Find the product's original position
        const productInOriginalPosition = state.originalProductSequences.find(
          (product) => product.productId === productId,
        );

        // Create updated sequence maintaining other pinned products at top
        const pinnedProducts = currentSequenceWithoutProduct.filter((product) =>
          updatedStoredPinned.includes(product.productId),
        );

        const unpinnedSequenceProducts = currentSequenceWithoutProduct.filter(
          (product) => !updatedStoredPinned.includes(product.productId),
        );

        let updatedSequence = [...pinnedProducts];

        // Insert the reverted product in its original position among unpinned products
        if (productInOriginalPosition) {
          // Find positions of non-pinned products in original sequence
          const originalUnpinnedProducts =
            state.originalProductSequences.filter(
              (product) => !updatedStoredPinned.includes(product.productId),
            );

          const originalUnpinnedIndex = originalUnpinnedProducts.findIndex(
            (p) => p.productId === productId,
          );

          updatedSequence = [
            ...pinnedProducts,
            ...unpinnedSequenceProducts.slice(0, originalUnpinnedIndex),
            productInOriginalPosition,
            ...unpinnedSequenceProducts.slice(originalUnpinnedIndex),
          ];
        }

        // Update sequences with correct sequence numbers
        const finalSequence = updatedSequence.map((product, index) => ({
          ...product,
          sequence: index + 1,
        }));

        return {
          ...state,
          storedPinnedProductIds: updatedStoredPinned,
          storedUnpinnedProductIds: unpinnedProducts,
          originalStoredPinnedProductIds: updatedStoredPinned,
          productSequences: finalSequence,
          editSequenceList: finalSequence,
          updatedStateProductIds: finalSequence.map(
            (product) => product.productId,
          ),
          sequenceMode:
            updatedStoredPinned.length > 0 ||
            unpinnedProducts.length > 0 ||
            state.savedLockedProductsInCategory.length > 0 ||
            state.savedUnlockedProductsInCategory.length > 0
              ? SEQUENCE_MODE_TYPE.EDIT
              : SEQUENCE_MODE_TYPE.VIEW,
        };
      }

      // Original logic for new pin/unpin actions
      if (!isPinned) {
        unpinnedProducts = [productId, ...unpinnedProducts];
      } else {
        unpinnedProducts = unpinnedProducts.filter((id) => id !== productId);
      }

      // Combine both pinned product arrays and remove duplicates
      const combinedPinnedProducts = [
        ...new Set([...pinnedProductsFromDB, ...existingStoredPinnedProducts]),
      ];

      // Update the combined list based on the new pin action
      const updatedPinnedProducts = isPinned
        ? [...new Set([...combinedPinnedProducts, productId])]
        : combinedPinnedProducts.filter((id) => !unpinnedProducts.includes(id));

      // First identify locked positions and create product mapping
      const lockedPositions = new Set();
      const productList = new Array(state.productSequences.length);
      let counter = 0;

      // Create separate arrays for different product types
      const lockedProducts: ProductSequenceData[] = [];
      const pinnedSequenceProducts: ProductSequenceData[] = [];
      const unpinnedSequenceProducts: ProductSequenceData[] = [];
      const remainingProducts: ProductSequenceData[] = [];

      // First pass: Categorize all products
      state.productSequences.forEach((product) => {
        const isLocked = state.locksInCategory?.some(
          (locked) =>
            locked.productId === product.productId &&
            locked.catalogId === catalogId &&
            locked.categoryId === categoryId,
        );

        if (isLocked) {
          lockedProducts.push(product);
        } else if (updatedPinnedProducts.includes(product.productId)) {
          pinnedSequenceProducts.push(product);
        } else if (
          state.storedUnpinnedProductIds.includes(product.productId) ||
          (product.productId === productId && !isPinned)
        ) {
          unpinnedSequenceProducts.push(product);
        } else {
          remainingProducts.push(product);
        }
      });

      // Sort unpinnedSequenceProducts based on the order in unpinnedProducts
      unpinnedSequenceProducts.sort((a, b) => {
        const indexA = unpinnedProducts.indexOf(a.productId);
        const indexB = unpinnedProducts.indexOf(b.productId);
        return indexA - indexB;
      });

      let position = 0;

      // Place locked products in their original positions
      lockedProducts.forEach((product, index) => {
        const originalIndex = state.productSequences.findIndex(
          (p) => p.productId === product.productId,
        );
        lockedPositions.add(originalIndex + 1);
        productList[originalIndex] = {
          ...product,
          sequence: originalIndex + 1,
        };
        position = Math.max(position, originalIndex + 1);
      });

      // Place pinned products at the top (after any locked products at the start)
      pinnedSequenceProducts.forEach((product) => {
        while (lockedPositions.has(++counter)) {} // Skip locked positions
        productList[counter - 1] = {
          ...product,
          sequence: counter,
        };
      });

      // Place unpinned products
      unpinnedSequenceProducts.forEach((product) => {
        while (lockedPositions.has(++counter)) {} // Skip locked positions
        productList[counter - 1] = {
          ...product,
          sequence: counter,
        };
      });

      // Place remaining products
      remainingProducts.forEach((product) => {
        while (lockedPositions.has(++counter)) {} // Skip locked positions
        productList[counter - 1] = {
          ...product,
          sequence: counter,
        };
      });

      // Filter out any undefined entries
      const updatedProductSequences = productList.filter(Boolean);
      const shouldEnableEditMode =
        pinnedSequenceProducts.length > 0 ||
        unpinnedSequenceProducts.length > 0 ||
        state.savedLockedProductsInCategory.length > 0 ||
        state.savedUnlockedProductsInCategory.length > 0;
      return {
        ...state,
        storedPinnedProductIds: updatedPinnedProducts,
        storedUnpinnedProductIds: unpinnedProducts,
        originalStoredPinnedProductIds: updatedPinnedProducts,
        productSequences: updatedProductSequences,
        editSequenceList: updatedProductSequences,
        updatedStateProductIds: updatedProductSequences.map(
          (product) => product.productId,
        ),
        sequenceMode: shouldEnableEditMode
          ? SEQUENCE_MODE_TYPE.EDIT
          : SEQUENCE_MODE_TYPE.VIEW,
      };
    }
    case SET_PINNED_PRODUCTS.SUCCESS: {
      const setPinnedProductsResponse = action.payload;
      return {
        ...state,
        pinnedProductIds: setPinnedProductsResponse.pinnedProductIds,
        storedUnpinnedProductIds: [],
        storedPinnedProductIds: [],
        originalStoredPinnedProductIds: [],
      };
    }
    case GET_PINNED_PRODUCTS.SUCCESS: {
      const getPinnedProductsResponse =
        action.payload as getPinnedProductsResponse;
      return {
        ...state,
        pinnedProductIds:
          getPinnedProductsResponse.results[0]?.pinnedProductIds ?? [],
      };
    }
    case RESET_STORED_PINNED_UNPINNED_PRODUCT_IDS: {
      return {
        ...state,
        storedPinnedProductIds: [],
        storedUnpinnedProductIds: [],
        originalStoredPinnedProductIds: [],
      };
    }
    case RESET_PINNED_UNPINNED_PRODUCTS: {
      return {
        ...state,
        pinnedProductIds: [],
        storedPinnedProductIds: [],
        storedUnpinnedProductIds: [],
        originalStoredPinnedProductIds: [],
      };
    }
    default:
      return state;
  }
};
