import React, { useContext } from "react";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import AppState from "../../store/AppState";
import {
  selectProductAnalyticsDataById,
  selectIsProductAnalyticsFetched,
  selectActiveSortingRule,
  selectProductFetched,
} from "../../store/product/ProductSelectors";
import { Typography } from "@mui/material";
import { selectIsOverlaySelectedByType } from "../../store/overlay/OverlaySelectors";
import { selectCurrencyCode } from "../../store/store-list/StoreListSelectors";
import { CURRENCY_MAP, OVERLAY_TABS } from "../../utils/Constants";
import { selectSortingRulesList } from "../../store/product-list/ProductListSelectors";
import { selectSystemGeneratedRules } from "../../store/businessRules-list/BusinessRuleslistSelectors";
import {
  StyledContainer,
  StyledList,
  StyledListItemDiv,
  StyledListItemValueDiv,
  StyledTitleDiv,
  useStyles,
} from "./AnalyticsOverlayStyles";
import { ViewContext } from "context/view-context";
import { useIntl } from "react-intl";

interface Props {
  productId: string;
  showProductAnalytics?: boolean;
  loadSingleProductAnalytics?: boolean;
  isSubstituteOverlay?: boolean;
}

const priceRelatedAnalytics = ["Price", "Stock Value", "Item Sales"];

const AnalyticsOverlay: React.FC<Props> = (props) => {
  const {
    productId,
    showProductAnalytics,
    loadSingleProductAnalytics,
    isSubstituteOverlay,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const intl = useIntl();
  const isAnalyticsOverlay = useSelector((state: AppState) =>
    selectIsOverlaySelectedByType(state, OVERLAY_TABS.ANALYTICS_OVERLAY),
  );
  const product = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const analyticsData = useSelector((state: AppState) =>
    selectProductAnalyticsDataById(state, productId),
  );
  const view = useContext(ViewContext);
  const columns = view.styles.column;
  const changeStyles =
    columns === 2
      ? { paddingLeft: "6.25rem" }
      : columns === 3
        ? { paddingLeft: "3.75rem" }
        : { paddingLeft: "inherit" };
  const substituteChangeStyles = { paddingLeft: "1.875rem" };
  const isAnalyticsFetched = useSelector(selectIsProductAnalyticsFetched);
  const isAnalyticsAvailable = useSelector(selectIsProductAnalyticsFetched);
  const currencyCode = useSelector(selectCurrencyCode);
  const sortingRule = useSelector(selectActiveSortingRule);
  const sortingRulesList = useSelector(selectSortingRulesList);
  const systemGeneratedRulesList = useSelector(selectSystemGeneratedRules);
  const allSortingRulesList = [
    ...systemGeneratedRulesList,
    ...sortingRulesList,
  ];
  const activeMetricsWs = allSortingRulesList
    .find((rule) => rule.ruleId === sortingRule)
    ?.formula?.map((metric) => metric.metric || []);
  const activeMetricMcs =
    allSortingRulesList
      .find((rule) => rule.ruleId === sortingRule)
      ?.criteria?.map((criterion) =>
        criterion.formula?.map((metric) => metric.metric),
      )
      .filter((metric) => metric !== null)
      .flat() || [];

  const activeClass = (name) => {
    if (activeMetricsWs?.includes(name) || activeMetricMcs.includes(name)) {
      return classes.listItemSelected;
    } else {
      return "";
    }
  };

  let currencySymbol = "$";
  if (CURRENCY_MAP[currencyCode]) {
    currencySymbol = CURRENCY_MAP[currencyCode];
  }

  const AnalyticsOverlayContent = () => (
    <StyledContainer isSubstituteOverlay={isSubstituteOverlay}>
      <StyledList
        className={`analytics-list`}
        style={isSubstituteOverlay ? substituteChangeStyles : changeStyles}
      >
        <StyledTitleDiv>
          <Typography variant="analytics" noWrap={true}>
            {columns !== 6 && columns !== 5
              ? `SKU: ${product?.code}`
              : product?.code}
          </Typography>
        </StyledTitleDiv>
        {columns !== 6 && columns !== 5 && (
          <div style={classes.analyticsTitleStyle}>
            <Typography variant="analytics">
              {intl.formatMessage({
                id: "analytics.title",
                defaultMessage: "Analytics",
              })}
            </Typography>
          </div>
        )}
        {isAnalyticsAvailable &&
          analyticsData &&
          analyticsData.length > 0 &&
          analyticsData.map((data) => (
            <li key={data.name} style={classes.listItem}>
              <StyledListItemDiv>
                <Typography
                  variant="analyticsList"
                  noWrap={true}
                  sx={{ ...activeClass(data.name) }}
                >
                  {data.name}:
                </Typography>
              </StyledListItemDiv>
              <StyledListItemValueDiv>
                <Typography
                  variant="analyticsList"
                  align={"right"}
                  sx={{ ...activeClass(data.name) }}
                >
                  {priceRelatedAnalytics.includes(data.name)
                    ? currencySymbol + data.value
                    : data.value}
                </Typography>
              </StyledListItemValueDiv>
            </li>
          ))}
      </StyledList>
    </StyledContainer>
  );

  const LoadingContent = () => (
    <StyledContainer isSubstituteOverlay={isSubstituteOverlay}>
      <StyledList className={`analytics-list`}>
        <li
          key={"loading"}
          className={`${classes.listItem} ${activeClass("loading")}`}
        >
          <StyledListItemDiv>
            <Typography variant="inherit" noWrap={true}>
              Loading...
            </Typography>
          </StyledListItemDiv>
        </li>
      </StyledList>
    </StyledContainer>
  );

  if (loadSingleProductAnalytics) {
    return <LoadingContent />;
  } else if (isAnalyticsOverlay) {
    if (isAnalyticsFetched) {
      if (showProductAnalytics !== false) {
        return <AnalyticsOverlayContent />;
      } else {
        return <></>;
      }
    } else {
      return <LoadingContent />;
    }
  } else if (showProductAnalytics) {
    return <AnalyticsOverlayContent />;
  } else {
    return <></>;
  }
};

export default React.memo(AnalyticsOverlay);
