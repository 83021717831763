import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { deleteContentSlot } from "store/product-list/ProductListActions";
import { selectModalVariable } from "store/modal/ModalSelectors";

const useStyles = makeStyles(() =>
  createStyles({
    btnGroup: {
      textAlign: "end",
      margin: "20px 20px 10px",
      width: "100%",
    },
    divMessage: {
      marginTop: "5px",
    },
  }),
);

export const ConfirmRemoveContentSlotModalId = "ConfirmRemoveContentSlot";
const ConfirmRemoveContentSlotModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmRemoveContentSlot"),
  );
  const slotId = useSelector((state: AppState) =>
    selectModalVariable(state, "ConfirmRemoveContentSlot", "slotId"),
  );

  const handleClose = () => {
    dispatch(setModalState("ConfirmRemoveContentSlot", false));
  };

  const handleDelete = () => {
    dispatch(deleteContentSlot({ slotId }));
  };

  const confirmRemoveContentSlotModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "confirmRemoveContentSlot.header",
          defaultMessage: "Remove Content Slot",
        })}
      </Typography>
    );
  };

  const confirmRemoveContentSlotModalContent = () => {
    return (
      <div className={classes.divMessage}>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmRemoveContentSlot.instruction",
            defaultMessage: "Are you sure you want to remove the content slot?",
          })}
        </Typography>
      </div>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.btnGroup}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            handleDelete();
            handleClose();
          }}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={confirmRemoveContentSlotModalTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      }}
    >
      {confirmRemoveContentSlotModalContent()}
    </CustomDialog>
  );
};

export default ConfirmRemoveContentSlotModal;
