import AppState from "../AppState";
import {
  Product,
  ProductSwatchType,
  ToChildProduct,
  ProductInventory,
  ProductAttributeValue,
  LeadingColor,
  ColorNode,
  AnalyticsData,
  Items,
  ItemManagementSFCCData,
  DatePickerPayload,
  CategoryType,
  categorySpecificData,
  singleProductAnalyticsView,
} from "./ProductTypes";
import {
  ProductNode,
  ChildrenItemNode,
  AnalyticsNode,
  ProductAttributesNode,
  SelectedSwatchNode,
  ChildAttributesNode,
  ProductCategoryNode,
} from "./ProductReducers";
import { createSelector } from "reselect";
import {
  isProductPublished,
  isProductPublishedSFCC,
  isProductPublishedSFCCStore,
  getProductSwatchLocaleSequenceSFCC,
} from "../../utils/ProductUtil";
import { ProductSequenceData } from "../product-list/ProductListTypes";
import {
  getProductName,
  getAttributeDisplayNameSFCC,
  getAttributeValueDisplayNameSFCC,
  getAttributeValueSequenceSFCC,
  getProductImageSFCC,
} from "../../utils/ProductUtil";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
  selectCurrencyCode,
} from "../store-list/StoreListSelectors";
import { selectReportProducts } from "../report/ReportSelectors";

export const selectProducts = (state: AppState): { [key: string]: Product } => {
  return state.products && state.products.products;
};

export const selectProductColors = (state: AppState) =>
  state.products?.productsColorData ?? {};

const mergeProductsColorData = (state: AppState, productId: string) => {
  let updatedProduct = { ...state.products.products[productId] };
  if (
    Object.keys(state.products.productsColorData).length > 0 &&
    Object.keys(state.products.productsColorData).includes(productId)
  ) {
    const colorProductObj = { ...state.products.productsColorData[productId] };
    let categorySpecificObj: categorySpecificData[] =
      updatedProduct.categorySpecific
        ? [...updatedProduct.categorySpecific]
        : [];
    colorProductObj.categorySpecific?.forEach((colorCategoryData) => {
      const index = categorySpecificObj?.findIndex(
        (obj) => obj.categoryId === colorCategoryData.categoryId,
      );
      if (index >= 0) {
        categorySpecificObj[index] = {
          ...categorySpecificObj[index],
          leadingColorId: colorCategoryData.leadingColorId,
        };
      } else {
        categorySpecificObj.push({ ...colorCategoryData });
      }
    });
    updatedProduct = {
      ...updatedProduct,
      hasSequenceableColors: colorProductObj.hasSequenceableColors,
      colors: colorProductObj.colors,
      colorVariationAttributeId: colorProductObj.colorVariationAttributeId,
      hasManageableColors: colorProductObj.hasManageableColors,
      categorySpecific: categorySpecificObj,
    };
    return updatedProduct;
  }
  return updatedProduct;
};
export const selectProductFetched = (
  state: AppState,
  productId: string,
): Product | null => {
  return state.products && state.products.products.hasOwnProperty(productId)
    ? mergeProductsColorData(state, productId)
    : null;
};

/* 
  https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  when the selector is used in multiple component instances and depends on the component's props, 
  you need to ensure that each component instance gets its own selector instance 
*/
export const makeSelectProductSwatches = () =>
  createSelector(
    (state: AppState) => state.products,
    (state: AppState, productId: string) => productId,
    (state: AppState, productId: string, mode: string) => mode,
    (
      state: AppState,
      productId: string,
      mode: string,
      localeSpecific: boolean,
    ) => localeSpecific,
    (
      state: AppState,
      productId: string,
      mode: string,
      localeSpecific: boolean,
      localeId: string,
    ) => localeId,
    (
      state: AppState,
      productId: string,
      mode: string,
      localeSpecific: boolean,
      localeId: string,
      defaultLocaleId: string,
    ) => defaultLocaleId,
    (
      products,
      productId,
      mode,
      localeSpecific,
      localeId,
      defaultLocaleId,
    ): ProductSwatchType[] | null => {
      let swatches =
        products && products.swatches.hasOwnProperty(productId)
          ? products.swatches[productId]
          : null;

      if (swatches && mode === "AttrVal") {
        swatches.sort((swatch1, swatch2) => {
          if (localeSpecific) {
            const localeSequence1 = getProductSwatchLocaleSequenceSFCC(
              swatch1,
              localeId,
              defaultLocaleId,
            );
            const localeSequence2 = getProductSwatchLocaleSequenceSFCC(
              swatch2,
              localeId,
              defaultLocaleId,
            );
            return localeSequence1 >= localeSequence2 ? 1 : -1;
          } else {
            return swatch1["attrValSequence"] >= swatch2["attrValSequence"]
              ? 1
              : -1;
          }
        });
      } else if (swatches && mode === "SKU") {
        swatches.sort((swatch1, swatch2) => {
          return swatch1["childProductSequence"] >=
            swatch2["childProductSequence"]
            ? 1
            : -1;
        });
      }

      return swatches;
    },
  );

export const selectedProductSwatches = (
  state: AppState,
): SelectedSwatchNode => {
  return state.products.selectedSwatches;
};

export const selectChildrenItems = (
  state: AppState,
  productId: string,
): ToChildProduct[] | null => {
  const toChildProductVariantAttributesArray =
    state.products.toChildrenItemsVariantAttributes[productId];
  if (toChildProductVariantAttributesArray) {
    const toChildProductArray = state.products.toChildrenItems[productId];
    const newToChildProductArray: ToChildProduct[] = [];
    toChildProductVariantAttributesArray.forEach((product) => {
      const oldToChildProduct = toChildProductArray.find(
        (item) => item.childProductId === product.childProductId,
      );
      if (oldToChildProduct && oldToChildProduct.childProduct) {
        const oldChildProduct = oldToChildProduct.childProduct;
        const oldAttributeValues = oldChildProduct.attributeValues;

        const newAttributeValues = [
          ...oldAttributeValues,
          ...product.childProduct.attributeValues,
        ];
        const newChildProduct = {
          ...oldChildProduct,
          attributeValues: newAttributeValues,
        };
        const newToChildProduct = {
          ...oldToChildProduct,
          childProduct: newChildProduct,
        };
        newToChildProductArray.push(newToChildProduct);
      }
    });
    return newToChildProductArray;
  }

  return state.products &&
    state.products.toChildrenItems.hasOwnProperty(productId)
    ? state.products.toChildrenItems[productId]
    : null;
};

export const selectChildrenPage = (state: AppState): number => {
  return state.products.childrenPage;
};

export const selectProductPriceForAllCurrencies = (
  state: AppState,
  productId: string,
) => {
  return state.products && state.products.prices.hasOwnProperty(productId)
    ? state.products.prices[productId]
    : null;
};

export const selectProductPrice = createSelector(
  [selectProductPriceForAllCurrencies, selectCurrencyCode],
  (productPrices, currencyCode) => {
    return productPrices && productPrices[currencyCode]
      ? productPrices[currencyCode]
      : null;
  },
);

export const selectProductInventory = (
  state: AppState,
  productId: string,
): ProductInventory | null => {
  return state.products && state.products.inventory.hasOwnProperty(productId)
    ? state.products.inventory[productId]
    : null;
};

export const selectProductLeadingColor = (
  state: AppState,
  productId: string,
): LeadingColor[] | null => {
  return state.products &&
    state.products.leadingColors.hasOwnProperty(productId)
    ? state.products.leadingColors[productId]
    : null;
};

export const selectProductLeadingImage = (
  state: AppState,
  productId: string,
  categoryId: string,
): string | null => {
  const search = state.products.products[productId]?.categorySpecific?.find(
    (e) => e.categoryId === categoryId,
  );
  const images = state.products?.products[productId]?.imageGroups ?? [];
  const productColorData = mergeProductsColorData(state, productId);
  let urlResponse = "";
  for (const image of images) {
    const index = image.images.findIndex(
      (e) => e.imageId === search?.leadingImageId,
    );
    const isColorPublished = image.variationAttributes?.some(
      (attr) =>
        attr.attributeId === productColorData.colorVariationAttributeId &&
        attr.values?.some(
          (attrValue) =>
            productColorData.colors?.find(
              (color) => color.colorId === attrValue.value,
            )?.isPublished,
        ),
    );

    const isProductNotHaveColors =
      image.variationAttributes?.some(
        (attr) =>
          attr.attributeId !== productColorData.colorVariationAttributeId,
      ) ?? true;
    if (
      (index >= 0 && isColorPublished) ||
      (index >= 0 && isProductNotHaveColors) //If product had leading image and it does not have colors then we are assigning leading image
    ) {
      urlResponse = image?.images?.[index]?.thumbnail;
    }
  }
  return state.products &&
    search &&
    state.products.products[productId].hasOwnProperty("imageGroups") &&
    state.products?.products[productId]?.imageGroups?.length
    ? urlResponse
    : null;
};

export const selectIsProductsFetched = (state: AppState): boolean => {
  return state.products && state.products.isFetched;
};

export const selectIsVariantFetched = (state: AppState): boolean => {
  return state.products.variants && state.products.variants.isVariantFetched;
};

export const selectIsProductChildrenFetching = (state: AppState): boolean => {
  return state.products && state.products.isProductChildrenFetching;
};

export const selectIsProductChildrenFetched = (state: AppState): boolean => {
  return state.products && state.products.isProductChildrenFetched;
};

export const selectIsProductAnalyticsFetched = (state: AppState): boolean => {
  return state.products && state.products.isAnalyticsFetched;
};

export const selectIsProductAttributesFetched = (state: AppState): boolean => {
  return state.products && state.products.isAttributesFetched;
};

export const selectAllProductsFetched = (state: AppState): ProductNode => {
  return state.products && state.products.products
    ? state.products.products
    : {};
  // return state?.products?.products ?? {};
};

export const selectProductCodes = createSelector(
  [selectAllProductsFetched],
  (products) => {
    return Object.values(products).map((product) => product.code);
  },
);

export const selectAllChildrenItems = (state: AppState): ChildrenItemNode => {
  return state.products && state.products.toChildrenItems
    ? state.products.toChildrenItems
    : {};
};

export const selectProductAttributeValues = (
  state: AppState,
): ProductAttributesNode => {
  return state.products && state.products.attributes;
};

export const selectChildAttributeValues = (
  state: AppState,
): ChildAttributesNode => {
  return state.products && state.products.childrenAttributes;
};

export const selectProductAttributeValueMap = createSelector(
  [selectProductAttributeValues],
  (attributes) => {
    let map: { [key: string]: string[] } = {};
    Object.keys(attributes).forEach((productId) => {
      const attrValues = attributes[productId].attributeValues.map(
        (attrValue) => attrValue.attributeValueId,
      );
      const localizedValues = attributes[
        productId
      ].attributeLocalizedValues.map((attrValue) => attrValue.attributeValueId);
      const siteSpecificValues = attributes[
        productId
      ].attributeValueOverrides.map((attrValue) => attrValue.attributeValueId);
      attrValues.push(...localizedValues);
      attrValues.push(...siteSpecificValues);
      map = {
        ...map,
        [productId]: attrValues,
      };
    });
    return map;
  },
);

export const selectAssignedAttributeIds = createSelector(
  [selectProductAttributeValues],
  (attributes) => {
    const attributeIdsSet = new Set<string>();
    Object.keys(attributes).forEach((productId) => {
      attributes[productId].attributeValues.forEach((attrValue) => {
        attributeIdsSet.add(attrValue.attributeValue.attributeId);
      });
      attributes[productId].attributeLocalizedValues.forEach((attrValue) => {
        attributeIdsSet.add(attrValue.attributeValue.attributeId);
      });
      attributes[productId].attributeValueOverrides.forEach((attrValue) => {
        attributeIdsSet.add(attrValue.attributeValue.attributeId);
      });
    });
    return Array.from(attributeIdsSet);
  },
);

export const selectItemManagementDataByProductId = createSelector(
  [
    selectChildrenItems,
    selectProductInventory,
    selectCurrentLocale,
    (state: AppState, productId: string, defaultLocaleId: string) =>
      defaultLocaleId,
  ],
  (childrenItems, productInventory, localeId, defaultLocaleId) => {
    const localeCode = localeId || "";
    const attributeKeys = new Set<string>();
    if (childrenItems) {
      childrenItems
        .filter((item) => !item.childProduct.isDeleted)
        .forEach((item) => {
          item.childProduct.attributeValues.forEach(
            (attr: ProductAttributeValue) => {
              const attributeName = getAttributeDisplayNameSFCC(
                attr,
                localeCode,
                defaultLocaleId,
              );
              attributeKeys.add(attributeName);
            },
          );
        });
    }
    const items = childrenItems
      ? childrenItems
          .filter((item) => !item.childProduct.isDeleted)
          .map((item) => {
            let skuInventory = 0;
            if (productInventory && productInventory.childProductInventories) {
              const sku = productInventory.childProductInventories.find(
                (sku) => sku.childProductId === item.childProductId,
              );
              if (sku) {
                skuInventory = sku.inventory;
              }
            }
            let data: {
              SKU: string;
              [key: string]: string | number | boolean;
            } = {
              SKU: item.childProductId,
            };
            const localSet = new Set(attributeKeys);
            item.childProduct.attributeValues.forEach(
              (attr: ProductAttributeValue) => {
                const attributeName = getAttributeDisplayNameSFCC(
                  attr,
                  localeCode,
                  defaultLocaleId,
                );
                if (localSet.has(attributeName)) localSet.delete(attributeName);
                data = {
                  ...data,
                  [attributeName]: getAttributeValueDisplayNameSFCC(
                    attr,
                    localeCode,
                    defaultLocaleId,
                  ),
                };
              },
            );
            localSet.forEach((key) => {
              data = {
                ...data,
                [key]: "",
              };
            });
            data = {
              ...data,
              inventory: skuInventory,
              Published: isProductPublished(item.childProduct),
              Delete: false,
            };
            return data;
          })
      : [];
    return items;
  },
);

export const selectProductVariants = (state: AppState) => {
  return state.products.variants;
};

export const selectAlertViewType = (state: AppState) =>
  state.products.alertViewType;

export const selectItemManagementSFCCDataByProductId = createSelector(
  [
    selectChildrenItems,
    selectProductInventory,
    selectCurrentStoreId,
    selectCurrentLocale,
    (state: AppState, productId, defaultLocaleId: string) => defaultLocaleId,
    selectAlertViewType,
  ],
  (
    childrenItems,
    productInventory,
    currentStoreId,
    currentLocaleId,
    defaultLocaleId,
    alertViewType,
  ) => {
    const storeId = currentStoreId ? currentStoreId : "";
    const localeId = currentLocaleId ? currentLocaleId : "";
    const attributeKeys = new Set<string>();
    if (childrenItems) {
      childrenItems
        .filter((item) => !item.childProduct.isDeleted)
        .forEach((item) => {
          item.childProduct.attributeValues.forEach(
            (attr: ProductAttributeValue) => {
              const attributeName = getAttributeDisplayNameSFCC(
                attr,
                localeId,
                defaultLocaleId,
              );
              attributeKeys.add(attributeName);
            },
          );
        });
    }
    let items = childrenItems
      ? childrenItems
          .filter((item) => !item.childProduct.isDeleted)
          .map((item) => {
            let skuInventory = 0;
            if (productInventory && productInventory.childProductInventories) {
              const sku = productInventory.childProductInventories.find(
                (sku) => sku.childProductId === item.childProductId,
              );
              if (sku) {
                skuInventory = sku.inventory;
              }
            }
            let data: Partial<ItemManagementSFCCData> = {
              SKU: item.childProductId,
            };
            const localSet = new Set(attributeKeys);
            item.childProduct.attributeValues.forEach(
              (attr: ProductAttributeValue) => {
                const attributeName = getAttributeDisplayNameSFCC(
                  attr,
                  localeId,
                  defaultLocaleId,
                );
                if (localSet.has(attributeName)) localSet.delete(attributeName);
                data = {
                  ...data,
                  [attributeName]: getAttributeValueDisplayNameSFCC(
                    attr,
                    localeId,
                    defaultLocaleId,
                  ),
                };
              },
            );
            localSet.forEach((key) => {
              data = {
                ...data,
                [key]: "",
              };
            });
            data = {
              ...data,
              inventory: skuInventory,
              published: isProductPublishedSFCC(item.childProduct),
              publishedStore: isProductPublishedSFCCStore(
                item.childProduct,
                storeId,
              ),
              action: "delete",
            };

            return data;
          })
      : [];

    if (items && alertViewType === 1) {
      items = items.filter(
        (item) =>
          item && !item.published && item.inventory && item.inventory > 80,
      );
    }
    return items as ItemManagementSFCCData[];
  },
);

export const selectProductForColorMangementByIdSFCC = createSelector(
  [
    selectProductFetched,
    selectChildrenItems,
    selectProductPrice,
    selectProductInventory,
    selectProductLeadingColor,
    selectCurrentLocale,
    (
      state: AppState,
      productId,
      mode: string,
      localeSpecific: boolean,
      defaultLocaleId: string,
      leadingColorEnabled: boolean,
    ) => mode,
    (
      state: AppState,
      productId,
      mode: string,
      localeSpecific: boolean,
      defaultLocaleId: string,
      leadingColorEnabled: boolean,
    ) => localeSpecific,
    (
      state: AppState,
      productId,
      mode: string,
      localeSpecific: boolean,
      defaultLocaleId: string,
      leadingColorEnabled: boolean,
    ) => defaultLocaleId,
    (
      state: AppState,
      productId,
      mode: string,
      localeSpecific: boolean,
      defaultLocaleId: string,
      leadingColorEnabled: boolean,
    ) => leadingColorEnabled,
  ],
  (
    product,
    items,
    productPrice,
    productInventory,
    leadingColors,
    localeCode,
    mode,
    localeSpecific,
    defaultLocaleId,
    leadingColorEnabled,
  ) => {
    const localeId = localeCode || "";
    const childProductPrices = productPrice
      ? productPrice.childProductPrices
      : [];
    const childProductInventories = productInventory
      ? productInventory.childProductInventories
      : [];

    const colors: ColorNode = {};

    if (items && items.length > 0) {
      items.forEach((item) => {
        if (item.childProduct.attributeValues) {
          item.childProduct.attributeValues.forEach((attributeValueObj) => {
            const attributeValue = attributeValueObj.attributeValue;
            if (attributeValue.attribute.isColor) {
              const attrValId = attributeValueObj.attributeValueId;
              let colorItems: Items[] = [];
              const priceObj = childProductPrices.find((childProductPrice) => {
                return childProductPrice.childProductId === item.childProductId;
              });
              const inventoryObj = childProductInventories.find((inventory) => {
                return inventory.childProductId === item.childProductId;
              });
              let price = priceObj ? priceObj.price : null;
              let inventory = inventoryObj ? inventoryObj.inventory : 0;
              let lowPrice = price;
              let highPrice = price;
              let sequence;
              if (mode === "AttrVal" && localeSpecific)
                sequence = getAttributeValueSequenceSFCC(
                  attributeValueObj,
                  localeId,
                  defaultLocaleId,
                );
              else if (mode === "AttrVal" && !localeSpecific)
                sequence = attributeValueObj?.attributeValue?.sequence;
              else sequence = item.sequence;
              let isPublished = isProductPublishedSFCC(item.childProduct);
              if (colors.hasOwnProperty(attrValId)) {
                const colorHighPrice = colors[attrValId].highPrice;
                const colorLowPrice = colors[attrValId].lowPrice;
                colorItems = colors[attrValId].itemIds;
                inventory += colors[attrValId].inventory;
                if (
                  !price ||
                  (price &&
                    colorHighPrice &&
                    price > 0 &&
                    price < colorHighPrice)
                )
                  highPrice = colorHighPrice;
                if (!price || (price && colorLowPrice && price > colorLowPrice))
                  lowPrice = colorLowPrice;
                if (sequence > colors[attrValId].sequence)
                  sequence = colors[attrValId].sequence;
                if (!isPublished) isPublished = colors[attrValId].isPublished;
              }
              colorItems.push({
                id: item.childProductId,
                type: item.typeCode,
              });
              let colorImage = getProductImageSFCC(
                item.childProduct,
                localeId,
                defaultLocaleId,
              );
              const color = {
                id: attrValId,
                name: getAttributeValueDisplayNameSFCC(
                  attributeValueObj,
                  localeId,
                  defaultLocaleId,
                ),
                productImage: colorImage,
                itemIds: colorItems,
                inventory,
                price,
                lowPrice,
                highPrice,
                sequence,
                isPublished,
              };
              colors[attrValId] = color;
            }
          });
        }
      });
    }

    let name = "";
    let code = "";
    let image = "";
    let colorsArray = Object.values(colors);
    colorsArray = colorsArray.sort((val1, val2) => {
      return val1.sequence >= val2.sequence ? 1 : -1;
    });
    if (product) {
      name = getProductName(product, localeId, defaultLocaleId);
      code = product.code;

      let colorIndex = -1;
      if (leadingColorEnabled && leadingColors && leadingColors.length > 0) {
        colorIndex = colorsArray.findIndex(
          (color) => color.id === leadingColors[0].attributeValueId,
        );
      }
      if (colorIndex < 0) {
        colorIndex = colorsArray.findIndex((color) => color.isPublished);
      }
      colorIndex = colorIndex >= 0 ? colorIndex : 0;
      image =
        colorsArray.length > 0
          ? colorsArray[colorIndex].productImage
          : getProductImageSFCC(product, localeId, defaultLocaleId);
    }
    let price = productPrice ? productPrice.price.toString() : null;
    if (productPrice && productPrice.lowPrice < productPrice.highPrice) {
      price = productPrice.lowPrice + "-" + productPrice.highPrice;
    }

    const productForColorManagement = {
      name,
      code,
      price,
      image,
      colors: colorsArray,
      leadingColor:
        leadingColorEnabled && leadingColors && leadingColors.length > 0
          ? leadingColors[0].attributeValueId
          : "",
    };

    return productForColorManagement;
  },
);

export const selectAnalyticsDataForReport = createSelector(
  [selectReportProducts, (state) => state.products.analytics],
  (productIds, analytics) => {
    let analyticsData: { [key: string]: string | number }[] = [];
    productIds.forEach((productId) => {
      let analyticData = {};
      analytics.productAnalytics &&
        analytics.productAnalytics[productId] &&
        analytics.productAnalytics[productId].forEach((analytic) => {
          analyticData = {
            ...analyticData,
            [analytic.name]: analytic.value,
          };
        });
      analyticData = {
        productId,
        ...analyticData,
      };
      analyticsData.push(analyticData);
    });
    return analyticsData;
  },
);

export const selectAnalyticsDataFieldsForReport = (
  state: AppState,
): string[] => {
  if (
    state.products &&
    state.products.analytics &&
    state.products.analytics.productAnalytics &&
    Object.keys(state.products.analytics.productAnalytics).length > 0
  ) {
    const productId = Object.keys(state.products.analytics.productAnalytics)[0];
    return state.products.analytics.productAnalytics[productId].map(
      (analyticField) => analyticField.name,
    );
  }
  return [];
};

export const selectAnalyticsDataById = (
  state: AppState,
  productId: string,
): AnalyticsData[] => {
  const product = selectProductFetched(state, productId);
  return state.products && state.products.analytics && product
    ? state.products.analytics.productAnalytics[product.code]
    : [];
};

export const selectAnalyticsStartDate = (state: AppState): string => {
  return state.products && state.products.analytics
    ? state.products.analytics.startDate
    : "";
};

export const selectAnalyticsEndDate = (state: AppState): string => {
  return state.products && state.products.analytics
    ? state.products.analytics.endDate
    : "";
};

export const selectAllProductAnalytics = (state: AppState): AnalyticsNode => {
  const products = selectAllProductsFetched(state);
  const productAnalytics: AnalyticsNode = {};
  Object.values(products).forEach((product) => {
    productAnalytics[product.productId] = selectProductAnalyticsDataById(
      state,
      product.productId,
    );
  });
  return productAnalytics;
};

export const selectProductAnalyticsDataById = createSelector(
  [
    selectAnalyticsDataById,
    selectProductPrice,
    selectProductInventory,
    selectProductFetched,
  ],
  (analytics, productPrice, productInventory, productFetched) => {
    let allAnalyticsData: AnalyticsData[] = [];
    const inventory = productFetched ? productFetched.stock : 0;
    const lowPrice = productFetched?.lowPrice;
    const highPrice = productFetched?.highPrice;
    const price =
      lowPrice && highPrice
        ? lowPrice === highPrice
          ? `${lowPrice}`
          : `${lowPrice}-$${highPrice}`
        : lowPrice
          ? `${lowPrice}`
          : productFetched?.listPrice
            ? `${productFetched?.listPrice}`
            : "";
    const stockValue = productFetched?.stockValue ?? 0;
    allAnalyticsData.push({
      name: "Inventory",
      value: inventory,
    });
    allAnalyticsData.push({
      name: "Price",
      value: price,
    });
    allAnalyticsData.push({
      name: "Stock Value",
      value: stockValue.toFixed(2),
    });
    if (analytics && analytics.length > 0) {
      allAnalyticsData = allAnalyticsData.concat(analytics);
      const i = analytics.findIndex(
        (analytics) => analytics.name === "Items Sold",
      );
      const itemsSold = analytics[i].value as number;
      const sellThrough = (100 * itemsSold) / (itemsSold + inventory);
      allAnalyticsData.push({
        name: "Sell Through",
        value: sellThrough.toFixed(2) + "%",
      });
    }
    return allAnalyticsData;
  },
);

export const selectCategoryByProductId = (
  state: AppState,
  productId: string,
): CategoryType[] => {
  return state.products && state.products.category[productId]
    ? state.products.category[productId]
    : [];
};

export const selectCategoriesByProductId = (
  state: AppState,
): ProductCategoryNode => {
  return state.products && state.products.category
    ? state.products.category
    : {};
};

export const selectVariantAttributes = (state: AppState) =>
  state.products.categoryVariantAttributes;

export const selectVariantAttributesFetched = (state: AppState) =>
  state.products.fetchedVariants;

export const selectProductInventoryBySKUAttributeMapSFCC = createSelector(
  [
    selectProductInventory,
    selectChildrenItems,
    (
      state: AppState,
      productId: string,
      localeId: string,
      defaultLocaleId: string,
    ) => localeId,
    (
      state: AppState,
      productId: string,
      localeId: string,
      defaultLocaleId: string,
    ) => defaultLocaleId,
  ],
  (productInventory, childrenItems, localeId, defaultLocaleId) => {
    let productInventoryByAttributes: { [key: string]: any } = {};
    childrenItems &&
      childrenItems.forEach((item) => {
        if (!item.childProduct.isPublished) {
          return;
        }
        const itemId = item.childProductId;
        item.childProduct.attributeValues.forEach((attribute) => {
          //const attributeName = getAttributeDisplayNameSFCC(attribute, localeId, defaultLocaleId);
          const attributeName = attribute.attributeValue.attribute.code;
          const attributeValue = getAttributeValueDisplayNameSFCC(
            attribute,
            localeId,
            defaultLocaleId,
          );
          const itemInventory =
            productInventory &&
            productInventory.childProductInventories.find(
              (inv) => inv.childProductId === itemId,
            );
          if (productInventoryByAttributes.hasOwnProperty(attributeName)) {
            if (
              productInventoryByAttributes[attributeName].hasOwnProperty(
                attributeValue,
              )
            ) {
              productInventoryByAttributes[attributeName][attributeValue] +=
                itemInventory ? itemInventory.inventory : 0;
            } else {
              productInventoryByAttributes[attributeName] = {
                ...productInventoryByAttributes[attributeName],
                [attributeValue]: itemInventory ? itemInventory.inventory : 0,
              };
            }
          } else {
            productInventoryByAttributes = {
              ...productInventoryByAttributes,
              [attributeName]: {
                [attributeValue]: itemInventory ? itemInventory.inventory : 0,
              },
            };
          }
        });
      });
    return productInventoryByAttributes;
  },
);

export const selectSKUAttributes = createSelector(
  [
    selectAllChildrenItems,
    selectVariantAttributes,
    (state: AppState, localeId: string, defaultLocaleId: string) => localeId,
    (state: AppState, localeId: string, defaultLocaleId: string) =>
      defaultLocaleId,
  ],
  (allChildrenItems, variantAttributes, localeId, defaultLocaleId) => {
    let attributeList = {};
    Object.keys(allChildrenItems).forEach((key) => {
      allChildrenItems[key].forEach((item) => {
        item.childProduct.attributeValues.forEach((attribute) => {
          //const attributeName = getAttributeDisplayNameSFCC(attribute, localeId, defaultLocaleId);
          attributeList = {
            ...attributeList,
            [attribute.attributeValue.attribute.code]: null,
          };
        });
      });
    });
    Object.keys(variantAttributes).forEach((attr) => {
      attributeList = {
        ...attributeList,
        [attr]: null,
      };
    });
    return Object.keys(attributeList);
  },
);

export const selectIsFetchedBySearch = (state: AppState): boolean => {
  return state?.products.isFetchedBySearch;
};

export const selectProductListBySearch = (
  state: AppState,
): ProductSequenceData[] => {
  const products = state.products.products;
  return Object.keys(products).map((productId, index) => {
    const descriptions = products[productId].descriptions?.map((desc) => ({
      isPublished: desc.isPublished,
    }));
    const isPublished = products[productId].isPublished;
    const overrides = products[productId].overrides;
    const productPublishedData = {
      descriptions,
      isPublished,
      overrides,
    };
    return { productId, sequence: index, product: productPublishedData };
  });
};

export const selectProductIdsBySearch = (state: AppState): string[] => {
  return state.products.searchResultIds;
};

export const selectSearchTerm = (state: AppState): string => {
  return state.products.searchTerm;
};

export const selectChildProductIds = createSelector(
  [selectAllChildrenItems],
  (products) => {
    const productIds: string[] = [];
    const parentProductIds = Object.keys(products);
    parentProductIds.forEach((parentProductId) => {
      products[parentProductId].forEach((product) => {
        productIds.push(product.childProductId);
      });
    });

    return productIds;
  },
);

export const selectChildProductAttributeIsFetching = (state: AppState) =>
  state.products.isChildProductAttributeFetching;

export const selectChildProductAttributeIsFetched = (state: AppState) =>
  state.products.isChildProductAttributeFetched;

export const selectHasChildren = (
  state: AppState,
  productId: string,
): boolean => {
  if (
    state.products &&
    state.products.toChildrenItems.hasOwnProperty(productId)
  ) {
    return state.products.toChildrenItems[productId].length > 0;
  }

  return false;
};

export const selectDateRange = (state: AppState): DatePickerPayload => {
  return state.products && state.products.selectedDateRange;
};

export const selectProductType = (
  state: AppState,
  productId: string,
): string => {
  return state.products.products[productId]?.typeCode ?? "";
};

// export const selectClipboardProducts = (state: AppState) => {
//   return state.products.clipBoardData;
// };

export const selectRecentlyAddedNewProducts = (state: AppState) => {
  return state.products.recentlyAddedProducts;
};

export const selectIsProductDetailRefereshing = (
  state: AppState,
  productId: string,
) => {
  return state.products.products[productId]?.isRefreshing;
};

export const selectIsProductPublishFlag = (state: AppState) => {
  return state.products.isPublishedFlag;
};

export const selectActiveSortingRule = (state: AppState) => {
  return state.products.activeSortingRule;
};

export const selectVariantPublishedFlag = (state: AppState): boolean => {
  return state.products.isVariantFlagPublished;
};

export const selectProductAnalyticsData = (
  state: AppState,
  productCode: string,
): singleProductAnalyticsView => {
  return (
    state.products.analytics?.productView &&
    state.products.analytics?.productView[productCode]
  );
};

export const selectIsProductsLoading = (state: AppState) => {
  return state.products.isProductsLoading;
};

export const selectIsReassignVariationsLoading = (state: AppState) => {
  return state.products.isReassignVariationsLoading;
};

export const selectIsReassignVariationsCompleted = (state: AppState) => {
  return state.products.isReassignVariationsCompleted;
};
